import React from 'react';
import { Helmet } from 'react-helmet';
import isEmpty from 'lodash-es/isEmpty';
import { withRouter } from 'react-router-dom';

import configs from 'configs';
import { isMobile } from 'helpers';
import { WindowMessages } from 'connection/postMessages';
import { baseConsumerDecorator } from 'stores/base';
import { getHelmetInfo } from 'stores/base/accessors';

class AppHeader extends React.PureComponent {

  getHeaderInfo = () => {
    const info = {
      title: configs.title,
      description: configs.title,
    };

    const { helmetInfo } = this.props;

    if (!isEmpty(helmetInfo)) {
      info.title = helmetInfo.title || info.title;
      info.description = helmetInfo.description || info.description;
    }

    if (!isMobile()) {
      WindowMessages.call({
        action: 'setHeaderData',
        title: info.title,
        description: info.description,
      });
    }

    return info;
  }

  render() {
    const {
      title,
      description,
    } = this.getHeaderInfo();

    return (
      <Helmet>
        <meta charset="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1, user-scalable=0, shrink-to-fit=no" />
        <meta name="theme-color" content="#000000" />
        <title>
          {title}
        </title>
        <link rel="stylesheet" href="https://fonts.googleapis.com/icon?family=Material+Icons" />
        <meta name="description" content={description} />
      </Helmet>
    );
  }
}

const mapAuthContextToProps = state => ({
  helmetInfo: getHelmetInfo(state),
});

export default baseConsumerDecorator(
  withRouter(AppHeader),
  mapAuthContextToProps,
);
