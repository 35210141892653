/** Simple Getters
 * @function {getter}
 * @description The function is supposed to give store's some segment
 * @param {object} [{}] store
 * Application store instance
 */

export const getState = (store = {}) => store;
export const getRate = store => getState(store).rate;
export const getToasts = store => getState(store).toasts;
export const getSocketConnectionStatus = store => getState(store).isSocketConnected;
export const getPartnerConfig = store => getState(store).partnerConfig;
export const getHelmetInfo = store => getState(store).helmetInfo;
export const getExitURL = store => getState(store).exitURL;
export const getPartnerId = store => getState(store).partnerId;
export const getLang = store => getState(store).lang;
