export const betPatternPMU = [
  {
    label: 'simple',
    value: 0,
  },
  {
    label: 'reduced',
    value: 1,
  },
  {
    label: 'total',
    value: 2,
  },
];

export const PMUBetFlexiType = [
  {
    label: 'Select flexi',
    bitMask: 0,
    value: 1,
    outValue: 0,
    disabled: false,
  },
  {
    label: '50 %',
    bitMask: 1,
    value: 2,
    outValue: 2,
    disabled: false,
  },
  {
    label: '25 %',
    bitMask: 2,
    value: 4,
    outValue: 3,
    disabled: false,
  },
  {
    label: '10 %',
    bitMask: 4,
    value: 10,
    outValue: 4,
    disabled: false,
  },
];

/**
 * Object of bet types which will convert our local type id's to real id's
 */
export const betTypeIds = {
  1: 25,
  3: 26,
  13: 27,
  14: 28,
  32: 29,
  33: 30,
  36: 31,
  45: 32,
};
