/** Router Errorhandler
 * Base errorhandler layer for not successful routing results
 */

import React from 'react';

import Logger from 'logger';

class RouteError extends React.PureComponent {

  state = { hasError: false };

  // Here must be the fallback UI component
  static Component = <h1>Something went wrong.</h1>;

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    Logger.error(error);

    return { hasError: true };
  }

  componentDidCatch(error, info) {
    // You can also log the error to an error reporting service
    Logger.info(`${error}:: ${info}`);
  }

  render() {

    return this.state.hasError ? RouteError.Component : this.props.children;
  }
}

export default RouteError;
