import configs from 'configs';

export const getState = (state = {}) => state;

export const getLanguageObject = (state, langAbbr) => getState(state)[langAbbr] || getState(state)[configs.skin.preferences.language] || {};

/**
 * As a default value we will return key, because key is english version of word which we need to translate
 */
export const getTranslatedKey = (store, langAbbr, key) => getLanguageObject(store, langAbbr)[key] || key;

export const getTranslatedKeys = (store, langAbbr, keys) => {
  const list = getLanguageObject(store, langAbbr);
  return keys.reduce((acc, item) => {
    acc[item] = (list && list[item]) || item;
    return acc;
  }, {});
};
