import Logger from 'logger';

import { commandList } from 'connection/postMessages';

class PostMessageListener {

  listen(action, callback) {
    this.eventHandler = event => {
      const message = event.data.action;

      if (!message) return;

      if (commandList.includes(action)) {
        action === message && callback(event);
      } else {
        Logger.error(`There is no such an event ${action}`);
      }
    };

    window.addEventListener('message', this.eventHandler);

    return this.eventHandler;
  }

  remove(handler) {
    window.removeEventListener('message', handler);
  }

};

export default new PostMessageListener();
