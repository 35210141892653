import { Actions } from 'context-api-store';
import Auth from 'middlewares/auth';

class AuthStoreActions extends Actions {
  login = config => Auth.login(config, profile => {
    this.store({ authConfig: config });
  });

  restore = (config = this.memory.state.authConfig) => config ? Auth.restore(config, res => {
    this.store({ authConfig: res });
  }) : Promise.reject(new Error('Token is not defined'));

  logout = _ => Auth.logout(_ => {
    this.store({ authConfig: null });
    this.removeCache();
  });

  getProfile = _ => Auth.getProfile(profile => {
    this.store({ profile });
  });
}

export default AuthStoreActions;
