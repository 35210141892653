import React from 'react';

import PMUErrorBoundary from 'applications/PMURaces/routes/error';
import PmuStoreProvider from 'applications/PMURaces/store';
import AppHeader from 'components/statefull/app-header';

import Content from './components/statefull/content';

import './sass/index.scss';

const AppContent = () => (
  <PMUErrorBoundary>
    <PmuStoreProvider>
      <AppHeader />
      <Content />
    </PmuStoreProvider>
  </PMUErrorBoundary>
);

export default AppContent;
