import configs from 'configs';

const name = 'PMURaces';
const sportId = 181;
const hostUrl = configs.skin.hostUrl;
const pmuConfigs = configs.skin.applications.find(application => application.name === name);
const timezone = 'Europe/London';
const mobileUrl = configs.skin.mobileUrl;
const baseHost = configs.skin.baseHost || 'casinoGameRules';
const shortName = 'pmu';
const defaultLng = 'eng';

export default {
  name,
  sportId,
  hostUrl,
  timezone,
  mobileUrl,
  baseHost,
  shortName,
  defaultLng,
  ...pmuConfigs,
};
