import Logger from 'logger';

/** Class Network
 * Network status is representing a global state of any
 * web based application. Application design is forced to be responsible
 * for 'offline' and 'online' status's functionality.
 */

class Network {

  /**
   * @prop {boolean} [window.navigator.onLine] - isNetworkAvailable
   */

  isNetworkAvailable = window.navigator.onLine;

  /**
   * Creating a class is setting up event handlers only.
   * So that why it can be a singleton in application :)
   */

  constructor() {
    this.setHandlers();
  }

  /**
   * Navigator API has `onLine` status
   * change events, so here are binds for those events.
   */

  setHandlers() {
    window.addEventListener('offline', () => {
      this.isNetworkAvailable = false;
      Logger.info('Application go offline...');
    });
    window.addEventListener('online', () => {
      this.isNetworkAvailable = true;
      this.postOnline && this.postOnline();
      Logger.info('Application go online!!!');
    });
  }

  /**
   * @return {boolean} @link{Network#isNetworkAvailable} - current status of network connection
   */

  checkNetworkState() {
    return this.isNetworkAvailable;
  }

  /**
   * @param {function} fn - Function can be stored here, for later execution
   * when network status will became `online`
   */

  callAfterConnect(fn) {
    this.postOnline = fn;
  }
}

export default new Network();
