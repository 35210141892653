const commandList = [
  'openHelp',
  'openSlider',
  'closeSlider',
  'login',
  'restore_login',
  'logout',
  'setLocation',
  'setRouteState',
  'appReady',
  'initialConfig',
  'setHeaderData',
];

export default commandList;
