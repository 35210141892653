import React from 'react';
import { withRouter } from 'react-router-dom';

import { WindowMessages } from 'connection/postMessages';
import { baseConsumerDecorator } from 'stores/base';
import { preferencesConsumerDecorator } from 'stores/preferences';
import {
  getPartnerConfig,
  getSocketConnectionStatus,
  getToasts,
} from 'stores/base/accessors';
import * as baseActions from 'stores/base/actions/types';
import { getLanguage } from 'stores/preferences/accessors';
import routes from 'applications/PMURaces/routes';
import isEmpty from 'lodash-es/isEmpty';
import { pmuConsumerDecorator } from 'applications/PMURaces/store';
import {
  getCompetitions,
  getSelectedGame,
  getSelectedMarket,
  getSelectedCompetition,
  getMenu,
} from 'applications/PMURaces/store/accessors';
import * as pmuActions from 'applications/PMURaces/store/actions/types';

import Loader from 'components/stateless/loader';
import Toasts from 'components/stateless/toasts';

import Header from 'applications/PMURaces/components/statefull/header';
import NotFound from 'applications/PMURaces/pages/notFound';

class Content extends React.PureComponent {

  static slashCharacter = encodeURIComponent('/');

  state = {
    isBusy: true,
    needToDrawFallback: false,
  };

  componentDidMount() {
    this.setState({ isBusy: true });
    this.props.getAllCompetitions().finally(() => this.setState({ isBusy: false }));
    this.props.getCmsTexts(this.props.language);
    this.listenToRouteChange();
  }

  listenToRouteChange = _ => {
    this.props.history.listen(location => {
      const paths = location.pathname.split('/') || [];
      const page = paths[1];
      let postMessagesData = {};

      this.props.routeChangeHandle(page);

      switch (page) {
        case 'horses': {
          const [ , route, subRoute, compId, gameId, date ] = paths;

          postMessagesData = {
            route: `${route}/${subRoute}`,
            compId,
            gameId,
            date,
          };
          break;
        }
        default: {
          postMessagesData = {
            route: page,
          };
          break;
        }
      }

      const { route } = postMessagesData;

      if (route === '') {
        const selectedTab = this.props.menu.find(tab => tab.isSelected);
        this.props.history.push(selectedTab.route);
        WindowMessages.call({
          action: 'setLocation',
          reset: true,
          location: {
            ...postMessagesData,
            route: selectedTab.route.replace(/\//, Content.slashCharacter),
          },
        });
      } else {
        WindowMessages.call({
          action: 'setLocation',
          reset: true,
          location: {
            ...postMessagesData,
            route: route.replace(/\//, Content.slashCharacter),
          },
        });
      }
    });
  }

  componentDidUpdate(prevProps) {
    const {
      selectedCompetition: prevSelectedCompetition,
      partnerConfig: prevPartnerConfig,
      competitions: prevCompetitions,
      language: prevLanguage,
    } = prevProps;

    const {
      selectedCompetition,
      getAllGamesByDate,
      getGamesByDate,
      partnerConfig,
      competitions,
      getCmsTexts,
      language,
      getRate,
    } = this.props;

    if (prevLanguage !== language) {
      getCmsTexts(language);
    }

    if (!prevSelectedCompetition && selectedCompetition) {
      const promises = [getGamesByDate(), getAllGamesByDate()];
      this.setState({ isBusy: true });
      Promise.all(promises)
        .then(_ => this.setState({ isBusy: false }));

      return null;
    }

    if (!prevPartnerConfig && partnerConfig) {
      getRate(partnerConfig.currency);
    }

    this.setState({
      needToDrawFallback: !!(isEmpty(prevCompetitions) && !Object.keys(competitions).length),
    });
  }

  render() {
    if (this.state.isBusy) {
      return <Loader />;
    }

    if (this.state.needToDrawFallback) {
      return <NotFound
        content="Service is not available.Try later"
        showButtons={false}
      />;
    }

    return (
      <div className="pmu-global-wrapper">
        <Toasts
          autoCloseDuration={3}
          onRemove={this.props.removeToastById}
          toasts={this.props.toasts}
        />
        <Header />
        <div className="pmu-b">
          {routes}
        </div>
      </div>
    );
  }
}

const mapPMUActionsToProps = [
  pmuActions.doLogin,
  pmuActions.getAllCompetitions,
  pmuActions.getAllMarketsByGameId,
  pmuActions.getAllEventsByMarketId,
  pmuActions.getGamesByDate,
  pmuActions.getAllGamesByDate,
  pmuActions.getCmsTexts,
  pmuActions.getCmsBanners,
  pmuActions.routeChangeHandle,
];

const mapBaseActionToProps = [
  baseActions.getRate,
  baseActions.removeToastById,
];

const mapPMUContextToProps = state => ({
  competitions: getCompetitions(state),
  selectedGame: getSelectedGame(state),
  selectedMarket: getSelectedMarket(state),
  selectedCompetition: getSelectedCompetition(state),
  dateFilterForFinished: state.dateFilterForFinished,
  menu: getMenu(state),
});

const mapPreferencesContextToProps = state => ({
  language: getLanguage(state),
});

const mapBaseContextToProps = state => ({
  toasts: getToasts(state),
  partnerConfig: getPartnerConfig(state),
  isOnline: getSocketConnectionStatus(state),
});

export default preferencesConsumerDecorator(
  baseConsumerDecorator(
    pmuConsumerDecorator(withRouter(Content), mapPMUContextToProps, mapPMUActionsToProps),
    mapBaseContextToProps,
    mapBaseActionToProps,
  ),
  mapPreferencesContextToProps,
);
