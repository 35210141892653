const GAME_STATUS = {
  completed: 2,
  canceled: 3,
  upcoming: 0,
  live: 1,
};

const QUICK_BET_GAMES_COUNT = 3;

export {
  GAME_STATUS,
  QUICK_BET_GAMES_COUNT,
};
