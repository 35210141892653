import React, { PureComponent } from 'react';
import { navigate } from 'shared-router';
import { withRouter } from 'react-router-dom';

import Translate from 'components/stateless/translate';

class NotFound extends PureComponent {
  static defaultProps = {
    content: 'The page you requested has either been moved or removed from the site.',
    showButtons: true,
  };

  goHome() {
    navigate('/');
  }

  goToPreviousRoute() {
    this.props.history.goBack();
  }

  render() {
    return (
      <div className="pmu-no-data-wrapper">
        <img className="pmu-n-d-w" src="/images/pmu-no-data-1.jpg" alt="" />
        <div className="pmu-n-d-i">
          {/* <span className="pmu-n-d-i-t t-1"> */}
          {/* <Translate */}
          {/* text="oops" */}
          {/* /> */}
          {/* ... */}
          {/* </span> */}
          <span className="pmu-n-d-i-t t-2">
            <Translate
              text="COVID-19"
            />
          </span>
          {/* <span className="pmu-n-d-i-t t-3">{this.props.content}</span> */}
          {
            this.props.showButtons &&
            <div className="pmu-n-d-i-t-a">
              <span className="pmu-n-d-i-t-a-b ext-1" onClick={this.goHome}>
                <Translate
                  text="go_home"
                />
              </span>
              <span className="pmu-n-d-i-t-a-b ext-2" onClick={this.goToPreviousRoute}>
                <Translate
                  text="go_home"
                />
              </span>
            </div>
          }
        </div>
      </div>
    );
  }
}

export default React.memo(withRouter(NotFound));
