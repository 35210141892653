import React from 'react';

import { preferencesConsumerDecorator } from 'stores/preferences';
import { getLanguage } from 'stores/preferences/accessors';
import { translationsConsumerDecorator } from 'stores/translate';
import { getLanguageObject } from 'stores/translate/accessors';

const Translate = props => {
  const { translations, text } = props;
  return translations && Reflect.has(translations, text) ? translations[text] : text;
};

const mapTranslationContextToProps = (state, props) => ({
  translations: getLanguageObject(state, props.language),
});

const mapPreferencesContextToProps = state => ({
  language: getLanguage(state),
});

export default preferencesConsumerDecorator(
  translationsConsumerDecorator(
    React.memo(Translate),
    mapTranslationContextToProps,
  ),
  mapPreferencesContextToProps,
);
