const defaultRate = {
  name: 'EUR',
  rounding: 0,
  rate: 1,
};

export const getFormattedPrice = (price, rate = defaultRate) => {

  const formatter = new Intl.NumberFormat('en-GB', {
    style: 'currency',
    currencyDisplay: 'code',
    currency: rate.name,
    minimumFractionDigits: 0,
  });
  const roundedPrice = Number(price / rate.rate).toFixed(rate.rounding);
  return formatter.format(roundedPrice);
};
