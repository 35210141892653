import { Actions } from 'context-api-store';

class PreferencesStoreActions extends Actions {

  setLanguage = language => {
    console.log('language =>', language);
    this.store({ language });
  };

  setTimeFormat = timeFormat => {
    this.store({ timeFormat });
  };

  setOddFormat = oddFormat => {
    this.store({ oddFormat });
  };

  setTimeZone = timeZone => {
    this.store({ timeZone });
  };

  setSound = sound => {
    this.store({ sound });
  };

  setPreference = (key, value) => {
    this.store({ key: value });
  };

}

export default PreferencesStoreActions;
