import React from 'react';

import configs from '../configs';
import { tabNameComponentEnums } from '../constants';

const Horses = React.lazy(_ => import('../pages/horses'));

const routeConfigs = configs.submenu.map(menuItem => {
  const path = tabNameComponentEnums[menuItem.name];

  return {
    url: menuItem.route,
    redirect: null,
    isPrivate: !menuItem.isVisibleBeforeLogin,
    props: null,
    Component: React.lazy(_ => import(`../pages/${path}`)),
  };
});

const defaultSelectedTab = configs.submenu.find(menuItem => menuItem.isSelected);

const redirectRoute = {
  url: null,
  redirect: defaultSelectedTab.route,
  isPrivate: null,
  props: null,
  Component: null,
};

const horsesInnerRoute = {
  url: '/horses/races/:competitionId?/:raceId?/:date?',
  redirect: null,
  isPrivate: false,
  props: null,
  Component: Horses,
};

const routes = [
  ...routeConfigs,
  horsesInnerRoute,
  redirectRoute,
];

export default routes;
