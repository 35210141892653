import { configureStore } from 'context-api-store';
import BaseStoreActions from './actions';
import defaultState, { storeName } from './defaultState';

// can be changed to read from config
// or with helper function
const caching = function(state) {

  const storage = {};

  // write cachable content here

  storage.title = state.title;
  storage.exitURL = state.exitURL;
  storage.partnerId = state.partnerId;
  storage.lang = state.lang;

  return storage;
};

/* Configuring applications base storage.
 * This is storage over React ContextAPI.
 * Configuration function returns a provider
 * for providing storage data(Note, it must be parent of the storage `users`,
 * either the data will not be accessed) and a decorator function
 * that can wrap React Component and subscribe it to requested changes.
 */

const {
  StoreProvider: BaseStoreProvider,
  consumerDecorator: baseConsumerDecorator,
} = configureStore(storeName, defaultState, BaseStoreActions, caching);

export {
  baseConsumerDecorator,
};

export default BaseStoreProvider;
