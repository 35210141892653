import React from 'react';

import { RouteWrapper } from 'routes';
import PreferencesStoreProvider from 'stores/preferences';
import BaseStoreProvider from 'stores/base';
import AuthStoreProvider from 'stores/auth';

import Configure from './configure';

export default () => (
  <BaseStoreProvider>
    <AuthStoreProvider>
      <PreferencesStoreProvider>
        <RouteWrapper>
          <Configure />
        </RouteWrapper>
      </PreferencesStoreProvider>
    </AuthStoreProvider>
  </BaseStoreProvider>
);
