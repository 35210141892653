import React, { PureComponent } from 'react';
import { navigate } from 'shared-router';

import { authConsumerDecorator } from 'stores/auth';
import { getConfig } from 'stores/auth/accessors';

import * as baseActions from 'stores/base/actions/types';
import { baseConsumerDecorator } from 'stores/base';
import { translationsConsumerDecorator } from 'stores/translate';
import { preferencesConsumerDecorator } from 'stores/preferences';
import { getPartnerConfig } from 'stores/base/accessors';

import SubMenu from 'components/statefull/submenu';

import { getMenu } from 'applications/PMURaces/store/accessors';
import { pmuConsumerDecorator } from 'applications/PMURaces/store';
import { getLanguageObject } from 'stores/translate/accessors';
import { getLanguage } from 'stores/preferences/accessors';

class Header extends PureComponent {

  onItemClick = id => {
    const selectedTab = this.props.menu.find(menuItem => menuItem.id === id);
    this.setTitleByTab(selectedTab);
    selectedTab && navigate(selectedTab.route);
  };

  setTitleByTab = (selectedTab = this.props.menu.find(tab => tab.isSelected)) => {
    const { translations } = this.props;

    const tabInfo = {
      title: translations[`tab_title_${selectedTab.title}`],
      description: translations['tab_description'],
    };
    this.props.changeHelmeInfio(tabInfo);
  }

  render() {
    const visibleMenu = this.props.menu.filter(menuItem => !this.props.authConfig
      ? menuItem.isVisibleBeforeLogin
      : menuItem.isVisibleAfterLogin,
    );

    return <SubMenu menu={visibleMenu} onItemClick={this.onItemClick} />;
  }
}

const mapContextToProps = state => ({
  menu: getMenu(state),
});

const mapBaseActions = [ baseActions.changeHelmeInfio ];

const mapBaseContextToProps = state => ({
  partnerConfig: getPartnerConfig(state),
});

const mapTranslationContextToProps = (state, props) => ({
  translations: getLanguageObject(state, props.language),
});

const mapPreferencesContextToProps = state => ({
  language: getLanguage(state),
});

const mapAuthContextToProps = state => ({
  authConfig: getConfig(state),
});

export default baseConsumerDecorator(
  preferencesConsumerDecorator(
    translationsConsumerDecorator(
      pmuConsumerDecorator(
        authConsumerDecorator(
          Header,
          mapAuthContextToProps,
        ), mapContextToProps),
      mapTranslationContextToProps,
    ),
    mapPreferencesContextToProps,
  ),
  mapBaseContextToProps,
  mapBaseActions,
);
