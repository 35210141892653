import configs from 'configs';

const loadCaptcha = (siteKey, action, callback) => {
  const head = document.getElementsByTagName('head')[0];
  const script = document.createElement('script');
  script.type = 'text/javascript';
  script.src = `${configs.reCaptchaV3}?render=${siteKey}`;
  head.appendChild(script);
  return script.onload = onLoadCaptcha(callback, siteKey, action);
};

const onLoadCaptcha = (callback, siteKey, action) => async() => {
  const token = await getCaptchaToken(siteKey, action);
  callback(token);
};

const getCaptchaToken = (siteKey, action) => {
  return new Promise((resolve, reject) => {
    const {
      grecaptcha,
    } = window;

    grecaptcha.ready(() => {
      grecaptcha.execute(siteKey, { action }).then(token => {
        resolve(token);
      });
    });
  });
};

export default loadCaptcha;
