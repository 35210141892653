import betTypes from './betTypes';

export * from './betSleep';
export * from './enums';
export * from './game';
export * from './gameCategories';
export * from './horse';

const jokeyImageUrl = 'https://horseracing.betcoapps.com/pmuDefaultJockey.png';

export {
  betTypes,
  jokeyImageUrl,
};
