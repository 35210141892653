/** Authorization
 * Some like a service for authorizing application usage and
 * user interaction(client-server relation). Can extend some
 * business logic that is not include in the class implementation itself.
 */
import Logger from 'logger';
import swarmAPI from 'connection/socket';
import { loadCaptcha } from 'helpers';
import globalConfigs from 'configs';

class Auth {

  isLoggedIn = false;

  validate(token) {
    // token validation
  }

  async login(params, callback) {
    const siteKey = window.localStorage.getItem(`reCAPTCHA_site_key_${globalConfigs.skin.id}`);

    if (siteKey) {
      const action = 'login';
      await loadCaptcha(siteKey, action, token => {
        this.manage({
          command: 'validate_recaptcha',
          params: {
            action: action,
            g_recaptcha_response: token,
          },
        });
      });
    }

    return swarmAPI.manage({
      command: 'login',
      params,
    }, result => {
      this.isLoggedIn = true;
      Logger.success('User has successfully logged in');
      callback(result.data.data.profile);
    }, error => Logger.error(error));
  }

  restore(params, callback) {
    return swarmAPI.manage({
      command: 'restore_login',
      params,
    }, result => {
      Logger.success('User has successfully restored');
      this.isLoggedIn = true;
      callback(params);
    }, error => {
      callback(null);
      Logger.error(error);
    });
  }

  logout(callback) {
    return swarmAPI.manage({
      command: 'logout',
    }, result => {
      this.isLoggedIn = false;
      callback(result);
    });
  }

  checkState() {
    return this.isLoggedIn;
  }

  getProfile(callback) {
    return swarmAPI.manage({
      command: 'get',
      params: {
        source: 'user',
        what: {
          profile: [],
        },
      },
      subscribe: true, // ??? why subscribe
    }, result => {
      callback(result.data.data.profile);
    });
  }
}

export default new Auth();
