import React from 'react';
import {
  Route,
  Switch,
  Redirect,
  Router,
} from 'react-router-dom';
import { history } from 'shared-router';

import PrivateRoute from 'helpers/privateRoute';
import Loader from 'components/stateless/loader';
import { guid } from 'helpers';

/** @function makeApplications
 * @description Function helpes to produce routing
 * configurations. NOTE:: can be improved
 * @param {Object[]} routes
 * @param {?string} routes[].url
 * application urls
 * @param {?string} routes[].redirect
 * application's default redirection url.
 * @param {?ReactElement} routes[].Component
 * component that needs will mount under route
 * @param {?Object} routes[].props
 * any params need to pass to the mounting component
 * @param {boolean} routes[].isPrivate
 * @param {?string} prefix
 * String path for multiple applications to identify
 * current browsing session url
 */

const makeApplicationRoutes = (routes, prefix = '') => (
  <Switch>
    {
      routes.map(({ Component, url, redirect, props = null, isPrivate = false }) => {
        /* As the application has multiple React applications support, so
         * the router's url has a problem of representation. The problem appears when
         * inner application that can be bootstrapped as standalone application.
         * As a standalone application it can't save any reference to even single third party
         * data. So the application among configuration process will be prefixed by core configurations.
         * The configs can be loaded from other source.
         */
        const routePath = prefix ? `${prefix}${url}` : url;
        const routeToRedirect = prefix ? `${prefix}${redirect}` : redirect;
        return isPrivate
          ? props
            ? <PrivateRoute
              key={guid()}
              path={routePath}
              render={routeProps => <Component {...routeProps} {...props} />}
            />
            : <PrivateRoute
              key={guid()}
              path={routePath}
              component={Component}
            />
          : url
            ? props
              ? <Route
                key={guid()}
                path={routePath}
                exact={routePath === '/'}
                render={routeProps => <Component {...routeProps} {...props} />}
              />
              : <Route
                key={guid()}
                path={routePath}
                exact={routePath === '/'}
                render={routeProps => <Component {...routeProps} />}
              />
            : <Redirect to={routeToRedirect} key={guid()} />;
      })
    }
  </Switch>
);

/* As there can be multiple applications in one single
 * session, the browsing history must be shared through them.
 */

/*
 * Go to previous route if the route is in pmu application,
 * if it is in home page then do nothing,
 * otherwise go to pmu home page
 * @todo must be improved
 */

/* As mentioned in https://github.com/ReactTraining/react-router/issues/3948 issue,
 * for such cases recommended way is to use `Router` instead of `BrowserRouter`
 * Also included suspense layer as `<Loader />` component
 *
 * In case of multiple applications bootsrtap
 * application must be wrapped by `RouterWrapper`
 */

const RouteWrapper = props => (
  <Router history={history}>
    <React.Suspense fallback={<Loader />}>
      {props.children}
    </React.Suspense>
  </Router>
);

export {
  RouteWrapper,
};

export default makeApplicationRoutes;
