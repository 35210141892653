import configs from 'configs';
import api from 'connection/xhr';
import { Actions } from 'context-api-store';

const {
  skin: {
    preferences: {
      language: configLanguage,
    },
  },
} = configs;

class TranslationsStoreActions extends Actions {
  fetchLocal = (langAbbr, setLanguage) => api.custom(`/languages/${langAbbr}.json`)
    .then(language => {
      if (language && language[langAbbr]) {
        this.store({
          [langAbbr]: language[langAbbr],
        });
      } else if (configLanguage !== langAbbr) {
        this.fetchLocal(configLanguage);
        setLanguage(configLanguage);
      }
    });
}

export default TranslationsStoreActions;
