import { configureStore } from 'context-api-store';
import PreferencesActions from './actions';
import defaultState, { storeName } from './defaultState';

// can be changed to read from config
// or with helper function
const caching = function(state) {

  const storage = {};

  // write cachable content here

  storage.language = state.language;
  storage.timeFormat = state.timeFormat;
  storage.oddFormat = state.oddFormat;
  storage.timeZone = state.timeZone;
  storage.sound = state.sound;
  storage.key = state.key;

  return storage;
};

/* Configuring applications base storage.
 * This is storage over React ContextAPI.
 * Configuration function returns a provider
 * for providing storage data(Note, it must be parent of the storage `users`,
 * either the data will not be accessed) and a decorator function
 * that can wrap React Component and subscribe it to requested changes.
 */

const {
  StoreProvider: PreferencesStoreProvider,
  consumerDecorator: preferencesConsumerDecorator,
} = configureStore(storeName, defaultState, PreferencesActions, caching);

export {
  preferencesConsumerDecorator,
};

export default PreferencesStoreProvider;
