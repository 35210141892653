import moment from 'moment';
import configs from '../configs';
import { guid } from 'helpers';
import { betPatternPMU } from 'applications/PMURaces/components/statefull/betslip/constants';

const storeName = configs.name;

const submenu = configs.submenu.map(item => ({ ...item, id: guid() }));

export const getDefaultBetslipHorsesList = () => (betPatternPMU.reduce((acc, item) => {
  acc[item.label] = {
    A: [],
    B: [],
  };
  return acc;
}, {}));

export const getDefaultSpot = () => ({
  isSpot: false,
  count: 0,
  horses: [],
});

export const getDefaultBetSleep = () => ({
  spot: getDefaultSpot(),
  horses: getDefaultBetslipHorsesList(),
  betType: {
    betTypeInfo: {},
  },
  betTypeChoice: {},
});

export {
  storeName,
};

export default {
  // pmu store
  ...configs,
  submenu,
  rate: {},
  betSleep: getDefaultBetSleep(),
  participants: [],
  markets: {},
  user: null,
  games: {},
  selectedGame: null,
  finishedGames: {},
  dateFilterForFinished: {
    start: moment().startOf('day').unix(),
    end: moment().endOf('day').unix(),
  },
  events: {},
  competitions: {},
  refreshCount: 0,
  gamesFinalRates: {},
  cmsTexts: {},
  cmsBanners: {},
};
