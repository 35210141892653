import produce from 'immer';
import moment from 'moment';
import forOwn from 'lodash-es/forOwn';
import { Actions } from 'context-api-store';
import Auth from 'middlewares/auth';
import api from 'connection/xhr';

import isEmpty from 'lodash-es/isEmpty';

import { emptyArray, updater, getFibonaciNumbers, changeTimezone, loadCaptcha } from 'helpers';
import { GAME_STATUS, reverseBlock } from 'applications/PMURaces/constants';
import swarmAPI from 'connection/socket';

import configs from 'applications/PMURaces/configs';
import globalConfigs from 'configs';
import { getDefaultBetSleep, getDefaultBetslipHorsesList } from '../defaultState';

class PmuStoreActions extends Actions {
  static halfMinutesInMiliseconds = 30000;
  static fibonaciNumbers = getFibonaciNumbers(6);
  static successBetStatus = 'SuccessBet';

  getAllCompetitions = _ => {
    return new Promise((resolve, reject) => swarmAPI.manage({
      command: 'get',
      params: {
        source: 'betting',
        what: {
          competition: [],
        },
        where: {
          sport: {
            id: configs.sportId,
          },
        },
      },
    }, result => {
      this.store(produce(draftState => {
        draftState.competitions = { ...draftState.competitions, ...result.data.data.competition };
        draftState.selectedCompetition = Number(Object.keys(draftState.competitions)[0]);
      }));
      resolve();
    },
    error => reject(error)));
  };

  updateGameState = gameId => {
    this.listenGameArrivalByGameId(gameId);
    this.store(produce(draftState => {
      if (draftState.finishedGames[gameId]) {
        draftState.finishedGames[gameId].State = GAME_STATUS.completed;
      }
    }));
  };

  addGameWithDetailed = (compId, gameId, newGame) => {
    this.getFinishedGameDetailedInfoByGameId(gameId, true).then(newDetailed => {
      newDetailed && this.store(produce(draftState => {
        draftState.finishedGames[gameId] = {
          ...newGame,
          CompetitionId: compId,
          CompetitionName: newGame.team1_name,
          detailed: newDetailed,
        };
        draftState.games[compId].game[gameId].detailed = newDetailed;
      }));
    });
  };

  getNewGamesIds = (games, prevState) => {
    return Object.keys(games).reduce((acc, compId) => {
      const gamesByCompId = games[compId] && games[compId].game;

      if (gamesByCompId) {
        const addGames = Object.keys(gamesByCompId).filter(gameId =>
          gamesByCompId[gameId] && (!prevState.games[compId] || !prevState.games[compId].game[gameId]),
        );

        if (!isEmpty(addGames)) {
          acc[compId] = addGames;
        }
      }

      return acc;
    }, {});
  }

  getGamesByDate = (
    startDate = moment(),
    endDate = startDate,
  ) => {
    const start = changeTimezone(startDate).startOf('day').unix();
    const end = changeTimezone(endDate).endOf('day').unix();
    return new Promise((resolve, reject) => swarmAPI.manage({
      command: 'get',
      params: {
        source: 'betting',
        what: {
          game: [],
          competition: ['game'],
        },
        where: {
          game: {
            start_ts: {
              '@gt': Number(start),
              '@lte': Number(end),
            },
          },
          sport: {
            id: configs.sportId,
          },
        },
        subscribe: true,
      },
    }, async result => {
      const promises = [];
      this.store(produce(draftState => {
        Object.keys(result.data.data.competition).forEach(compId => {
          if (draftState.games[compId]) {
            draftState.games[compId].game = { ...draftState.games[compId].game, ...result.data.data.competition[compId].game };
          }
        });
        draftState.games = { ...result.data.data.competition, ...draftState.games };
        const selected = draftState.games[draftState.selectedCompetition];
        draftState.selectedGame = selected ? selected[Object.keys(selected.game)[0]] : null;
        draftState.start = start;
        draftState.end = end;
        draftState.startDate = startDate;
        draftState.endDate = endDate;
        draftState.dateFilterForFinished = {
          start,
          end,
          startDate,
          endDate,
        };

        forOwn(result.data.data.competition, (comp, key) => {
          forOwn(comp.game, game => {
            promises.push(this.getGameDetailedInfoByGameId(key, game.id));
          });
        });
      }));

      Promise.all(promises).then(() => {
        resolve();
      });
    }, error => {
      reject(error);
    }, update => {
      console.log('getGamesByDate---------');
      console.log('update', update);
      console.log('state', this.memory.state.games);

      const {
        competition = {},
      } = update;

      let deletedData;
      let gameIds = {};
      let newGames = null;

      this.store(produce(draftState => {

        if (!isEmpty(competition)) {
          newGames = this.getNewGamesIds(competition, draftState);
        }

        Object.keys(draftState.games).forEach(key => gameIds[key] = Object.keys(draftState.games[key].game));

        deletedData = updater(draftState, update.competition, 'games');
      }), () => {
        if (!isEmpty(newGames)) {
          Object.keys(newGames).forEach(compId => {
            newGames[compId].forEach(gameId => {
              this.addGameWithDetailed(compId, gameId, competition[compId].game[gameId]);
            });
          });
        }

        deletedData.forEach(id => {
          const otherIds = gameIds[id];
          !otherIds
            ? this.updateGameState(id)
            : otherIds.forEach(gameId => this.updateGameState(gameId));
        });
      });
    }));
  };

  listenGameArrivalByGameId = (gameId, fibonaciIndex = 0) => {

    if (fibonaciIndex === PmuStoreActions.fibonaciNumbers.length) {
      return;
    }

    setTimeout(async() => {
      const resultPromise = this.getFinishedGameArrivalInfoByGameId(gameId);

      const arrivalInfo = await resultPromise;

      if (!arrivalInfo) {
        this.listenGameArrivalByGameId(gameId, ++fibonaciIndex);
        return;
      }

      if (arrivalInfo.HasDividends) {
        this.getFinishedGameDetailedInfoByGameId(gameId, true).then(({
          raceInfo,
          matchInfo,
          dividends,
          results,
        }) => {
          this.store(produce(draftState => {
            const { detailed } = draftState.finishedGames[gameId];
            detailed.raceInfo = raceInfo;
            detailed.matchInfo = matchInfo;
            detailed.dividends = dividends;
            detailed.results = results;
          }));
        });
      } else {
        this.listenGameArrivalByGameId(gameId, ++fibonaciIndex);
      }

      this.store(produce(draftState => {
        draftState.finishedGames[gameId].detailed.results = arrivalInfo;
      }));
    }, PmuStoreActions.fibonaciNumbers[fibonaciIndex] * PmuStoreActions.halfMinutesInMiliseconds);
  }

  getAllGamesByDate = (
    startDate = moment(),
    endDate = startDate,
  ) => {
    const start = changeTimezone(startDate).startOf('day').unix();
    const end = changeTimezone(endDate).endOf('day').unix();
    return new Promise((resolve, reject) => swarmAPI.manage({
      command: 'get_pmu_matches',
      params: {
        from_date: start,
        to_date: end,
      },
    }, async result => {
      const finishedGames = {};
      const competitions = {};
      const detailedList = [];

      for (const key in result.data.details) {
        const gameId = result.data.details[key].Id;
        detailedList.push(
          this.getFinishedGameDetailedInfoByGameId(gameId, false)
            .then((detailed = {}) => {
              if (detailed.results && !detailed.results.HasDividends) {
                this.listenGameArrivalByGameId(gameId);
              }
              if (detailed.matchInfo) {
                finishedGames[gameId] = {
                  ...result.data.details[key],
                  detailed,
                  shortName: detailed.raceInfo && detailed.raceInfo.shortName && detailed.raceInfo.shortName.split(' ')[0],
                };
              }
            }),
        );
        competitions[result.data.details[key].CompetitionId] = {
          name: result.data.details[key].CompetitionName,
          id: result.data.details[key].CompetitionId,
        };
      }

      return Promise.all(detailedList).then(res => {
        this.store(produce(draftState => {
          draftState.competitions = { ...draftState.competitions, ...competitions };
          draftState.finishedGames = { ...draftState.finishedGames, ...finishedGames };
          draftState.dateFilterForFinished = {
            start,
            end,
            startDate,
            endDate,
          };
        }));
        resolve();
      });
    }, error => {
      reject(error);
    }));
  };

  getSelectionsByMatchId = matchId => {
    return new Promise((resolve, reject) => swarmAPI.manage({
      command: 'get_pmu_selections_by_match_id',
      params: {
        match_id: matchId,
      },
    }, ({ data: { details } }) => {
      this.store(produce(draftState => {
        draftState.gamesFinalRates[matchId] = Array.isArray(details) ? details : null;
        draftState.selectedGame = draftState.finishedGames[matchId];
      }));
      resolve(details);
    }, error => {
      reject(error);
    }));
  }

  getAllMarketsByGameId = gameId => {
    return swarmAPI.manage({
      command: 'get',
      params: {
        source: 'betting',
        what: {
          market: [],
          game: ['market'],
        },
        where: {
          game: {
            id: gameId,
          },
        },
        subscribe: true,
      },
    }, result => this.store(
      produce(draftState => {
        draftState.markets = { ...draftState.markets, ...result.data.data.game };
      }),
    ),
    error => console.log(error),
    update => {
      console.log('getAllMarketsByGameId---------');
      console.log('update', update);
      console.log('state', this.memory.state.markets);

      this.store(produce(draftState => {
        updater(draftState, update, 'markets');
      }), () => console.log('next state', this.memory.state.markets));
    });
  }

  /**
   * For reCaptcha v3 we need to handle route change.
  */

  routeChangeHandle(action) {
    const siteKey = window.localStorage.getItem(`reCAPTCHA_site_key_${globalConfigs.skin.id}`);

    if (siteKey) {
      const parsedAction = action.replace(/-([a-z])/g, g => g[1].toUpperCase());
      loadCaptcha(siteKey, parsedAction, token => {
        swarmAPI.manage({
          command: 'validate_recaptcha',
          params: {
            action: parsedAction,
            g_recaptcha_response: token,
          },
        }, result => console.log(result),
        error => console.error(error));
      });
    }
  }

  getAllMarketsGroupedByGameIds = gameIds => {
    return swarmAPI.manage({
      command: 'get',
      params: {
        source: 'betting',
        what: {
          market: [],
          game: ['market'],
        },
        where: {
          game: {
            id: {
              '@in': gameIds,
            },
          },
        },
        subscribe: true,
      },
    }, result => this.store(
      produce(draftState => {
        draftState.markets = { ...draftState.markets, ...result.data.data.game };
      }),
    ),
    error => console.log(error),
    update => {
      console.log('getAllMarketsGroupedByGameIds---------');
      console.log('update', update);
      console.log('state', this.memory.state.markets);

      this.store(produce(draftState => {
        updater(draftState, update, 'markets');
      }), () => console.log('next state', this.memory.state.markets));
    });
  }

  getAllEventsByMarketId = marketId => {
    return swarmAPI.manage({
      command: 'get',
      params: {
        source: 'betting',
        what: {
          event: [],
          market: ['event'],
        },
        where: {
          market: {
            id: marketId,
          },
        },
        subscribe: true,
      },
    }, result => this.store(
      produce(draftState => {
        draftState.events = { ...draftState.events, ...result.data.data.market };
      }),
    ),
    error => console.log(error),
    update => {
      console.log('getAllEventsByMarketId---------');
      console.log('update', update);
      console.log('state', this.memory.state.events);

      this.store(produce(draftState => {
        updater(draftState, update, 'events');
      }), () => console.log('next state', this.memory.state.events));
    });
  }

  getAllEventsGroupedByMarketIds = marketIds => {
    return swarmAPI.manage({
      command: 'get',
      params: {
        source: 'betting',
        what: {
          event: [],
          market: ['event'],
        },
        where: {
          market: {
            id: {
              '@in': marketIds,
            },
          },
        },
        subscribe: true,
      },
    }, result => {
      this.store(
        produce(draftState => {
          draftState.events = { ...draftState.events, ...result.data.data.market };
        }),
      );
    },
    error => console.log(error),
    update => {
      this.store(produce(draftState => {
        updater(draftState, update.market, 'events');
      }));
    });
  }

  getBetHistory = (fromDate, toDate, type, period, outcome) => {
    return swarmAPI.manage({
      command: 'get_pmu_bet_history',
      what: {
        bets: [],
      },
      params: {
        from_date: fromDate,
        to_date: toDate,
        type,
        outcome,
      },
    }, result => {
      this.store(
        produce(draftState => {
          if (!draftState.betHistory) {
            draftState.betHistory = result.data.details;
          } else {
            const existingBetHistoryIds = draftState.betHistory.map(bet => bet.Id);

            draftState.betHistory = [
              ...result.data.details.filter(bet => !existingBetHistoryIds.includes(bet.Id)),
              ...draftState.betHistory,
            ];
          }

          draftState.betHistoryFilterData = {
            selectedBetStatus: outcome,
            selectedBetType: type,
            selectedFrom: fromDate,
            selectedPeriod: period,
            selectedTo: toDate,
          };
        }),
      );
    },
    error => console.log(error),
    );
  }

  getGameDetailedInfoByGameId = (compId, gameId, isForce = false) => {
    const url = `${configs.hostUrl}/Matches/${gameId}/${gameId}.json`;
    const updateState = (draftState, result) => {
      if (result) {
        const {
          [compId]: compById,
          [compId]: {
            categories,
            game: {
              [gameId]: gameById,
            },
          },
        } = draftState.games;

        if (gameById) {
          gameById.detailed = result;
        }
        compById.shortName = result.raceInfo && result.raceInfo.shortName.split(' ')[0];
        if (categories) {
          categories[result.matchInfo.Id] = result.raceInfo.category;
        }
      }
    };

    if (!isForce && this.doesExistInStore(url)) {
      this.doesExistInStore(url).then(result => this.store(produce(draftState => {
        updateState(draftState, result);
      })));
    } else {
      this.storeInCache(url,
        api.custom({ baseURL: url, headers: { 'Cache-Control': 'no-cache' } })
          .then(result => result)
          .then(result => {
            this.store(produce(draftState => {
              updateState(draftState, result);
            }));
            return result;
          })
          .catch(error => console.log(error)));
    }
  };

  getFinishedGameDetailedInfoByGameId = async(gameId, isForce = false) => {
    const url = `${configs.hostUrl}/Matches/${gameId}/${gameId}.json`;

    if (!isForce && this.doesExistInStore(url)) {
      return this.doesExistInStore(url);
    } else {
      const promise = api.custom({ baseURL: url, headers: { 'Cache-Control': 'no-cache' } })
        .catch(error => console.log(error));

      this.storeInCache(url,
        promise,
      );
      return promise;
    }
  };

  getDividendsByGameId = async(gameId, isForce = false) => {
    const urlDividends = `${configs.hostUrl}/Matches/${gameId}/dividends.json`;

    if (!isForce && this.doesExistInStore(urlDividends)) {
      return this.doesExistInStore(urlDividends);
    } else {
      const promise = api.custom({ baseURL: urlDividends, headers: { 'Cache-Control': 'no-cache' } })
        .catch(error => console.log(error));

      this.storeInCache(urlDividends,
        promise,
      );
      return promise;
    }
  };

  getFinishedGameArrivalInfoByGameId = async(gameId) => {
    const url = `${configs.hostUrl}/Matches/${gameId}/results.json`;
    const promise = api.custom({ baseURL: url, headers: { 'Cache-Control': 'no-cache' } })
      .then(result => result)
      .catch(error => console.log(error));
    return promise;
  };

  placeBet = options => {
    return new Promise((resolve, reject) => Auth.isLoggedIn
      ? swarmAPI.manage({
        command: 'do_bet',
        params: {
          type: options.type,
          source: '42',
          mode: 1,
          bets: options.bets,
          amount: options.amount,
          odd_type: 0,
          additional_info: {
            is_spot: options.isSpot,
            is_all_order: options.isAllOrder,
            bet_pattern: options.betPattern,
            flexi_type: options.flexy,
            match_id: options.matchId,
            competitive_horse: options.supplement,
            bet_selections: options.betSelections,
            multiplier: options.multiplier,
          },
        },
      }, ({ data: { result_text: translationKey } }) => {
        resolve({
          translationKey: translationKey || PmuStoreActions.successBetStatus,
          isSuccess: !translationKey,
        });
      }, error => console.log(error))
      : reject(Error('Please log in first')));
  }

  doQuickBet = (eventId, price, amount, selectedHorse, matchId) => {
    return new Promise((resolve, reject) => Auth.isLoggedIn
      ? swarmAPI.manage({
        command: 'do_bet',
        params: {
          type: 25,
          source: '42',
          mode: 1,
          bets: [{
            event_id: eventId,
            price,
          }],
          amount,
          odd_type: 0,
          additional_info: {
            bet_pattern: 0,
            flexy_type: 0,
            match_id: matchId,
            bet_selections: [{
              is_base: false,
              order: null,
              horse_number: selectedHorse.number,
            }],
          },
        },
      }, result => {
        const translationKey = result.data && result.data.result_text ? result.data.result_text : PmuStoreActions.successBetStatus;
        resolve({ translationKey, isSuccess: translationKey === PmuStoreActions.successBetStatus });
      },
      error => console.log(error))
      : reject(Error('Please log in first')));
  };

  cancelBet = betId => {
    return swarmAPI.manage({
      command: 'cancel_pmu_bet',
      params: {
        bet_id: betId,
      },
    }, result => this.store(
      produce(draftState => {
        const updatedBetHistory = result.data.details;

        draftState.betHistory = draftState.betHistory.map(betHistory => {
          if (betHistory.Id === updatedBetHistory.Id) {
            return {
              ...betHistory,
              Outcome: updatedBetHistory.Outcome,
              Payout: updatedBetHistory.Payout,
            };
          }
          return betHistory;
        });
      }),
    ),
    error => console.log(error));
  }

  getCmsTexts = (lng = configs.defaultLng) => {
    const url = `${globalConfigs.hostUrl}/json?base_host=${configs.baseHost}&ssl=1&lang=${lng}&json=get_page&slug=pmu-${lng}`;
    const promise = api.custom({ baseURL: url })
      .then(result => {
        this.store(produce(draftState => {
          draftState.cmsTexts[lng] = result.page.children;
        }));
      })
      .catch(error => console.log(error));
    return promise;
  };

  getCmsBanners = (lng = configs.defaultLng) => {
    const url = `${globalConfigs.hostUrl}/json?base_host=${configs.baseHost}&ssl=1&lang=${lng}&json=widgets/get_sidebar&sidebar_id=pmu_banner-${lng}`;
    const promise = api.custom({ baseURL: url })
      .then(result => {
        this.store(produce(draftState => {
          draftState.cmsBanners[lng] = result.widgets;
        }));
      })
      .catch(error => console.log(error));
    return promise;
  };

  refreshApplicationState = _ => {
    this.store(
      produce(draftState => {
        draftState.refreshCount++;
      }),
    );
  }

  // ui actions

  storeSelectedBetType = (betType = { betTypeInfo: {} }) => this.store(produce(draftState => {
    draftState.betSleep.betType = betType;
  }));

  storeSelectedBetTypeChoice = (betTypeChoice = {}) => this.store(produce(draftState => {
    draftState.betSleep.betTypeChoice = betTypeChoice;
  }));

  storeSelectedHorses = horses => this.store(produce(draftState => {
    draftState.betSleep.horses = horses;
  }));

  resetStoreSelectedHorses = () => this.store(produce(draftState => {
    draftState.betSleep.horses = getDefaultBetslipHorsesList();
  }));

  storeSelectedSpot = isSpot => this.store(produce(draftState => {
    draftState.betSleep.spot.isSpot = isSpot;
  }));

  storeSelectedHorsesSpot = count => this.store(produce(draftState => {
    if (draftState.betSleep.spot.count !== count) {
      draftState.betSleep.spot.horses = [];
    }
    draftState.betSleep.spot.count = count;
  }));

  updateSelectedHorses = (horse, type, block, order = null) => this.store(produce(draftState => {
    draftState.betSleep.horses[type][block] = draftState.betSleep.horses[type][block] || [];
    if (order) {
      draftState.betSleep.horses[type][block] = draftState.betSleep.horses[type][block].filter(item => item.number !== horse.number);
      draftState.betSleep.horses[type][reverseBlock[block]] = draftState.betSleep.horses[type][reverseBlock[block]].filter(item => item.number !== horse.number);
      if (order !== 'associate') {
        draftState.betSleep.horses[type][block] = draftState.betSleep.horses[type][block].filter(item => item.chosedOrder !== horse.chosedOrder);
      }
    }

    if (!draftState.betSleep.horses[type][block].find(item => item.number === horse.number)) {
      draftState.betSleep.horses[type][block].push(horse);
    }

    draftState.betSleep.horses[type][reverseBlock[block]] = (draftState.betSleep.horses[type][reverseBlock[block]] || emptyArray).filter(item => item.number !== horse.number);
  }));

  removeSelectedHorses = (horse, type, block, order = null) => this.store(produce(draftState => {
    draftState.betSleep.horses[type][block] = draftState.betSleep.horses[type][block].filter(item => item.number !== horse.number);
  }));

  updateSelectedHorseSpot = (horse, order) => this.store(produce(draftState => {
    if (order) {
      draftState.betSleep.spot.horses = draftState.betSleep.spot.horses.filter(item => item.number !== horse.number);
      draftState.betSleep.spot.horses = draftState.betSleep.spot.horses.filter(item => item.chosedOrder !== horse.chosedOrder);
    }
    draftState.betSleep.spot.horses.push(horse);
  }));

  removeSelectedHorseSpot = (horse, order) => this.store(produce(draftState => {
    draftState.betSleep.spot.horses = draftState.betSleep.spot.horses.filter(item => item.number !== horse.number);
  }));

  removeSpotSelections = _ => this.store(produce(draftState => {
    draftState.betSleep.spot.horses = [];
  }));

  removeAllSelections = _ => this.store(produce(draftState => {
    draftState.betSleep = getDefaultBetSleep();
  }));
}

export default PmuStoreActions;
