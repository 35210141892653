
import React from 'react';
import classNames from 'classnames';
import { withRouter } from 'react-router-dom';

import { isMobile } from 'helpers';
import SubMenuItem from 'components/stateless/submenuItem';
import { getLanguage } from 'stores/preferences/accessors';
import { getTranslatedKeys } from 'stores/translate/accessors';
import { preferencesConsumerDecorator } from 'stores/preferences';
import { baseConsumerDecorator } from 'stores/base';
import { getExitURL } from 'stores/base/accessors';

import configs from 'applications/PMURaces/configs';

import { translationsConsumerDecorator } from 'stores/translate';

const translationKeys = [
  'go_home',
  'my_bets',
  'speedbet',
  'how_to_bet',
  'races_of_the_day',
  'program_and_results',
  'tv_channel',
];

class SubMenu extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      selectedId: this.getSelectedTabId(props.menu, props.location),
    };
    this.props.onItemClick && this.props.onItemClick(this.state.selectedId);
    this.listenToRouteChange();
  }

  handleClick = (event, selectedId) => {
    event.stopPropagation();

    if (selectedId !== this.state.selectedId) {
      this.props.onItemClick && this.props.onItemClick(selectedId);
    }
  };

  listenToRouteChange = _ => {
    this.props.history.listen((location, action) => {
      this.setState({ selectedId: this.getSelectedTabId(this.props.menu, location) });
    });
  };

  getSelectedTabId(menu, location) {
    const selectedTab = menu.find(menuItem => location.pathname.includes(menuItem.route));
    return selectedTab ? selectedTab.id : null;
  }

  goHome = () => {
    window.location.assign(this.props.exitURL || configs.mobileUrl);
  }

  render() {
    const { translations } = this.props;
    return (
      <div className="pmu-h">
        {isMobile() && (
          <SubMenuItem
            title={translations.go_home}
            handleClick={this.goHome}
          />
        )}
        {
          this.props.menu.map(menuItem => (
            <SubMenuItem
              key={menuItem.id}
              id={menuItem.id}
              title={translations[menuItem.title]}
              handleClick={this.handleClick}
              classNames={classNames({ active: menuItem.id === this.state.selectedId })}
            />
          ))
        }
      </div>
    );
  }
}

const mapPreferencesContextToProps = state => ({
  language: getLanguage(state),
});

const mapTranslationContextToProps = (state, props) => ({
  translations: getTranslatedKeys(state, props.language, Object.values(translationKeys)),
});

const mapBaseContextToProps = state => ({
  exitURL: getExitURL(state),
});

export default baseConsumerDecorator(
  preferencesConsumerDecorator(
    translationsConsumerDecorator(
      withRouter(SubMenu),
      mapTranslationContextToProps,
    ),
    mapPreferencesContextToProps,
  ),
  mapBaseContextToProps,
);
