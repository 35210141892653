export const doLogin = 'doLogin';
export const placeBet = 'placeBet';
export const cancelBet = 'cancelBet';
export const doQuickBet = 'doQuickBet';
export const getBetHistory = 'getBetHistory';
export const getGamesByDate = 'getGamesByDate';
export const storeSelectedSpot = 'storeSelectedSpot';
export const getAllCompetitions = 'getAllCompetitions';
export const removeAllSelections = 'removeAllSelections';
export const storeSelectedHorses = 'storeSelectedHorses';
export const resetStoreSelectedHorses = 'resetStoreSelectedHorses';
export const removeSelectedHorse = 'removeSelectedHorse';
export const removeSpotSelections = 'removeSpotSelections';
export const updateSelectedHorses = 'updateSelectedHorses';
export const storeSelectedBetType = 'storeSelectedBetType';
export const removeSelectedHorses = 'removeSelectedHorses';
export const refreshApplicationState = 'refreshApplicationState';
export const getAllEventsByMarketId = 'getAllEventsByMarketId';
export const getAllMarketsByGameId = 'getAllMarketsByGameId';
export const storeSelectedHorsesSpot = 'storeSelectedHorsesSpot';
export const updateSelectedHorseSpot = 'updateSelectedHorseSpot';
export const removeSelectedHorseSpot = 'removeSelectedHorseSpot';
export const getAllGamesByDate = 'getAllGamesByDate';
export const storeSelectedBetTypeChoice = 'storeSelectedBetTypeChoice';
export const getAllMarketsGroupedByGameIds = 'getAllMarketsGroupedByGameIds';
export const getAllEventsGroupedByMarketIds = 'getAllEventsGroupedByMarketIds';
export const getSelectionsByMatchId = 'getSelectionsByMatchId';
export const getCmsTexts = 'getCmsTexts';
export const getCmsBanners = 'getCmsBanners';
export const routeChangeHandle = 'routeChangeHandle';
