/* API class
  The class described below is an easy interface for
  using REST full API rules. In the whole application is used only
  one instance of this class somelike a Singleton.
*/

import axios from 'axios';

import configs from 'configs';
import errorHandler from './errorHandler';

class API {
  constructor(url) {
    this.connection = axios.create({
      baseURL: url,
      headers: configs.defaultHeaders,
      withCredentials: configs.withCredentials,
    });
  }

  async create(url, data, query = '', headers = this.defaultHeaders) {
    return await this.generic({ url: `/${url}${query}`, data, headers, method: 'post' });
  }

  async getItem(url, id, query = '', headers = this.defaultHeaders) {
    return await this.generic({ url: `/${url}/${id}${query}`, headers });
  }

  async getList(url, query = '', headers = this.defaultHeaders) {
    return await this.generic({ url: `/${url}${query}`, headers });
  }

  async updateItem(url, id, data, query = '', headers = this.defaultHeaders) {
    return await this.generic({ url: `/${url}/${id}${query}`, method: 'put', data, headers });
  }

  async modifyItem(url, id, data, query = '', headers = this.defaultHeaders) {
    return await this.generic({ url: `${url}/${id}${query}`, method: 'patch', data, headers });
  }

  async deleteItem(url, id, query = '', headers = this.defaultHeaders) {
    return await this.generic({ url: `${url}/${id}${query}`, method: 'delete', headers });
  }

  async generic(options) {
    return await /* Auth.validateRequest(async options => await */ this.__request(options)/*, options) */;
  }

  async __request(options) {
    return await this.connection(options).catch(errorHandler.manage).then(res => res.data);
  }

  async custom(options) {
    return await axios(options).catch(errorHandler.manage).then(res => res && res.data);
  }
}

const api = new API(configs.hostUrl);

export default api;
