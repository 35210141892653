/** ErrorHandler
 * Base Errorhandler class for application specific errors and
 * their handling. Can be extended for connection error handling,
 * some user validations, etc.
 */

import Logger from 'logger';

class ErrorHandler {

  /** @function manage
   * @param {object | string} message
   * Act with non successful data and inform via logger.
   */
  manage(message) {
    Logger.warn(message);
  }
}

export default ErrorHandler;
