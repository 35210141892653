/** Logger
 * Application level logger
 * Can be extended for some non regular usage.
 */

class Logger {

  /**
   * @param {string} message
   * Function needs to inform about some event that happened in application
   * Information needs to be something that is in preparing process and has no result already
   */

  static info(message) {
    console.info(message);
  }

  /**
   * @param {string} message
   * Function needs to warn about some process that has not been done
   */

  static warn(message) {
    console.warn(message);
  }

  /**
   * @param {string} message
   * Event for successful result
   */

  static success(message) {
    console.log(`%c${message}`, 'color: green');
  }

  /**
   * @param {string} message
   * Event for non successful result
   */

  static error(message) {
    console.error(message);
  }
}

export default Logger;
