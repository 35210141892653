const tabNameComponentEnums = {
  'Speedbet': 'speedBet',
  'speedbet': 'SpeedBet',
  'My bets': 'myBets',
  'howToBet': 'How to bet',
  'How to bet': 'howToBet',
  'Races of the day': 'racesOfTheDay',
  'racesOfTheDay': 'Races of the day',
  'Program and results': 'programAndResults',
  'programAndResults': 'Program and results',
  'TV Channel': 'tvChannel',
  'tvChannel': 'TV Channel',
};
const reverseBlock = {
  'A': 'B',
  'B': 'A',
};

export {
  reverseBlock,
  tabNameComponentEnums,
};
