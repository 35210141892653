const betTypes = [
  {
    iconName: 'bc-i-pmu-simple-gagnant',
    name: 'Simple Gagnant',
    slug: 'simple_gagnant',
    shortName: 'GAG',
    ordered: false,
    minSelected: 1,
    maxSelected: 1,
    isVisible: true, // this property is to show only bet types valid for pmu
    flexiTypeBitMask: 0,
    patternBitMask: 0,
    type: 1,
  },
  {
    iconName: 'bc-i-pmu-simple-place',
    name: 'Simple Place',
    slug: 'simple_place',
    shortName: 'PLA',
    ordered: false,
    minSelected: 1,
    maxSelected: 1,
    isVisible: true,
    flexiTypeBitMask: 0,
    patternBitMask: 0,
    type: 3,
  },
  {
    iconName: 'bc-i-pmu-simple-gagnant',
    name: '2 sur 4',
    shortName: '2S4',
    ordered: false,
    minSelected: 2,
    maxSelected: 2,
    isVisible: false,
    flexiTypeBitMask: 2,
    patternBitMask: 3,
    type: 8,
  },
  {
    iconName: 'bc-i-pmu-simple-gagnant',
    name: 'Tierce',
    shortName: 'TRC',
    ordered: true,
    minSelected: 3,
    maxSelected: 3,
    isVisible: false,
    flexiTypeBitMask: 0,
    patternBitMask: 3,
    type: 10,
  },
  {
    iconName: 'bc-i-pmu-couple-gagnant',
    name: 'Couple Gagnant',
    slug: 'couple_gagnant',
    shortName: 'COG',
    ordered: false,
    minSelected: 2,
    maxSelected: 2,
    isVisible: true,
    flexiTypeBitMask: 1,
    patternBitMask: 3,
    type: 13,
  },
  {
    iconName: 'bc-i-pmu-couple-place',
    name: 'Couple Place',
    slug: 'couple_place',
    shortName: 'COP',
    ordered: false,
    minSelected: 2,
    maxSelected: 2,
    isVisible: true,
    flexiTypeBitMask: 1,
    patternBitMask: 3,
    type: 14,
  },
  {
    iconName: 'bc-i-pmu-simple-gagnant',
    name: 'Quarte Plus',
    shortName: 'QAP',
    ordered: true,
    minSelected: 4,
    maxSelected: 4,
    isVisible: false,
    flexiTypeBitMask: 0,
    patternBitMask: 3,
    type: 17,
  },
  {
    iconName: 'bc-i-pmu-simple-gagnant',
    name: 'Multi',
    shortName: 'MTI',
    ordered: false,
    minSelected: 4,
    maxSelected: 7,
    isVisible: false,
    flexiTypeBitMask: 2,
    patternBitMask: 3,
    type: 29,
  },
  {
    iconName: 'bc-i-pmu-simple-gagnant',
    name: 'Classic Tierce',
    shortName: 'CLT',
    ordered: true,
    minSelected: 2,
    maxSelected: 2,
    isVisible: false,
    flexiTypeBitMask: 0,
    patternBitMask: 3,
    type: 30,
  },
  {
    iconName: 'bc-i-pmu-couple-ordre',
    name: 'Couple Ordre',
    slug: 'couple_ordre',
    shortName: 'COO',
    ordered: true,
    minSelected: 2,
    maxSelected: 2,
    isVisible: true,
    flexiTypeBitMask: 1,
    patternBitMask: 3,
    type: 32,
  },
  {
    iconName: 'bc-i-pmu-trio',
    name: 'Trio',
    slug: 'trio',
    shortName: 'TRO',
    ordered: false,
    minSelected: 3,
    maxSelected: 3,
    isVisible: true,
    flexiTypeBitMask: 1,
    patternBitMask: 3,
    type: 33,
  },
  {
    iconName: 'bc-i-pmu-simple-gagnant',
    name: 'Simple gagnant international',
    shortName: 'SGI',
    ordered: false,
    minSelected: 1,
    maxSelected: 1,
    isVisible: false,
    flexiTypeBitMask: 0,
    patternBitMask: 3,
    type: 34,
  },
  {
    iconName: 'bc-i-pmu-quinte-plus',
    name: 'Quinte +',
    slug: 'quinte',
    shortName: 'QIP',
    ordered: true,
    minSelected: 5,
    maxSelected: 5,
    isVisible: true,
    flexiTypeBitMask: 3,
    patternBitMask: 3,
    type: 36,
  },
  {
    iconName: 'bc-i-pmu-trio-ordre',
    name: 'Trio Ordre',
    slug: 'trio_ordre',
    shortName: 'TOO',
    ordered: true,
    minSelected: 3,
    maxSelected: 3,
    isVisible: true,
    flexiTypeBitMask: 1,
    patternBitMask: 3,
    type: 45,
  },
  {
    iconName: 'bc-i-pmu-simple-gagnant',
    name: 'Couple Order International',
    shortName: 'COI',
    ordered: true,
    minSelected: 2,
    maxSelected: 2,
    isVisible: false,
    flexiTypeBitMask: 1,
    patternBitMask: 3,
    type: 53,
  },
  {
    iconName: 'bc-i-pmu-simple-gagnant',
    name: 'Trio Order International',
    shortName: 'TOI',
    ordered: true,
    minSelected: 3,
    maxSelected: 3,
    isVisible: false,
    flexiTypeBitMask: 1,
    patternBitMask: 3,
    type: 54,
  },
  {
    iconName: 'bc-i-pmu-simple-gagnant',
    name: 'Simple Place International',
    shortName: 'SPI',
    ordered: false,
    minSelected: 1,
    maxSelected: 1,
    isVisible: false,
    flexiTypeBitMask: 0,
    patternBitMask: 3,
    type: 114,
  },
  {
    iconName: 'bc-i-pmu-simple-gagnant',
    name: 'Gagnant Jackpot',
    shortName: 'GAJ',
    ordered: false,
    minSelected: 1,
    maxSelected: 1,
    isVisible: false,
    flexiTypeBitMask: 0,
    patternBitMask: 3,
    type: 115,
  },
  {
    iconName: 'bc-i-pmu-simple-gagnant',
    name: 'Place Jackpot',
    shortName: 'PLJ',
    ordered: false,
    minSelected: 1,
    maxSelected: 1,
    isVisible: false,
    flexiTypeBitMask: 0,
    patternBitMask: 3,
    type: 116,
  },
  {
    iconName: 'bc-i-pmu-simple-gagnant',
    name: '2 sur 4 Jackpot',
    shortName: '2J4',
    ordered: false,
    minSelected: 2,
    maxSelected: 2,
    isVisible: false,
    flexiTypeBitMask: 0,
    patternBitMask: 3,
    type: 125,
  },
  {
    iconName: 'bc-i-pmu-simple-gagnant',
    name: 'Superfecta',
    shortName: 'SPR',
    ordered: true,
    minSelected: 4,
    maxSelected: 4,
    isVisible: false,
    flexiTypeBitMask: 0,
    patternBitMask: 3,
    type: 149,
  },
  {
    iconName: 'bc-i-pmu-simple-gagnant',
    name: 'Pick 5',
    shortName: 'QIT',
    ordered: false,
    minSelected: 5,
    maxSelected: 5,
    isVisible: false,
    flexiTypeBitMask: 6,
    patternBitMask: 3,
    type: 153,
  },
  {
    iconName: 'bc-i-pmu-simple-gagnant',
    name: 'Mini-Multi',
    shortName: 'MMI',
    ordered: false,
    minSelected: 4,
    maxSelected: 6,
    isVisible: false,
    flexiTypeBitMask: 2,
    patternBitMask: 3,
    type: 162,
  },
];

export const BET_STATUSES = {
  0: 'unsettled',
  1: 'lost',
  2: 'returned',
  3: 'won',
};

// Bet type code mapping from json data to swarm data
export const BET_TYPE_IDS = {
  1: 25,
  3: 26,
  13: 27,
  14: 28,
  32: 29,
  33: 30,
  36: 31,
  45: 32,
};

export default betTypes;
