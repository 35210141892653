import React from 'react';
import className from 'classnames';
import PropTypes from 'prop-types';

import { preferencesConsumerDecorator } from 'stores/preferences';
import { translationsConsumerDecorator } from 'stores/translate';
import { getLanguage } from 'stores/preferences/accessors';
import { getTranslatedKeys } from 'stores/translate/accessors';

import Toast from './toast';

const autoCloseSecond = 5000;

const autoClose = (duration, rid, cb) => setTimeout(() => cb(rid), duration * autoCloseSecond);

const translationKeys = {
  BetAmountError: 'BetAmountError',
  BetTypeError: 'BetTypeError',
  BetValidationError: 'BetValidationError',
  PartnerApiError: 'PartnerApiError',
  ClientBetStakeLimitError: 'ClientBetStakeLimitError',
  SuccessBet: 'SuccessBet',
};

const Toasts = ({ toasts, onRemove, autoCloseDuration, onClick, translations }) => (
  <div className={className('pmu-message-box', {
    show: toasts.length,
  })}>
    <div className="p-m-b-c">
      {toasts.map(toast => {
        const { rid } = toast;
        if (autoCloseDuration) {
          autoClose(autoCloseDuration, rid, onRemove);
        }

        const msg = toast.translationKey ? translations[translationKeys[toast.translationKey]] : toast.msg;

        return (
          <Toast
            key={rid}
            {...toast}
            msg={msg}
            autoClose={!!autoCloseDuration}
            onClick={(e) => onClick && onClick(e, rid)}
            onDismissClick={() => onRemove(rid)}
          />
        );
      })}
    </div>
  </div>
);

Toasts.propTypes = {
  onRemove: PropTypes.func.isRequired,
  toasts: PropTypes.arrayOf(PropTypes.object).isRequired,
  autoCloseDuration: PropTypes.number,
  onClick: PropTypes.func,
};

const mapPreferencesContextToProps = state => ({
  language: getLanguage(state),
});

const mapTranslationContextToProps = (state, props) => ({
  translations: getTranslatedKeys(state, props.language, Object.values(translationKeys)),
});

export default preferencesConsumerDecorator(
  translationsConsumerDecorator(
    React.memo(Toasts),
    mapTranslationContextToProps,
  ),
  mapPreferencesContextToProps,
);
