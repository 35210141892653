/** Application configs
 * Here can be merged the skin specific config
 */

export default {
  hostUrl: process.env.REACT_APP_HOSTURL,
  geoUrl: process.env.REACT_APP_GEOURL,
  defaultHeaders: {
    'Content-Type': process.env.REACT_APP_CONTENT_TYPE,
  },
  defaultCurrency: 'AMD',
  withCredentials: Boolean(process.env.REACT_APP_WITH_CREDENTIALS),
  routePrefix: '',
  title: process.env.REACT_APP_PROJECT_NAME,
  reCaptchaV3: 'https://www.google.com/recaptcha/api.js',
};
