import React from 'react';
import { navigate } from 'shared-router';
import { withRouter } from 'react-router-dom';

import moment from 'moment';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider } from 'material-ui-pickers';

import Logger from 'logger';
import configs from 'configs';
import { loadConfigs, postMessageListener, isMobile } from 'helpers';

import { preferencesConsumerDecorator } from 'stores/preferences';
import { baseConsumerDecorator } from 'stores/base';
import { authConsumerDecorator } from 'stores/auth';
import { getLanguage } from 'stores/preferences/accessors';
import TranslationProvider from 'stores/translate';
import * as baseActions from 'stores/base/actions/types';
import * as authActions from 'stores/auth/actions/types';
import * as preferencesActions from 'stores/preferences/actions/types';
import { getConfig, getProfile } from 'stores/auth/accessors';
import {
  getLang,
  getPartnerId,
  getSocketConnectionStatus,
} from 'stores/base/accessors';
import queryStringParser from 'helpers/queryStringParser';

import swarmAPI from 'connection/socket';
import AppContent from './appContent';

import Loader from 'components/stateless/loader';

import './configure.scss';
import 'skins/sass/skin-override.scss';

class App extends React.PureComponent {

  state = {
    isPreloading: true,
    isChangedLanguage: false,
  };

  /**
   * When redirected to us from the mobile version,
   * the token and exitURL are sent to the URL.
   */

  componentDidMount() {
    if (isMobile()) {
      const token = queryStringParser(this.props.location.search, 'AuthToken');

      this.props.history.push('');
      this.promise.then(_ => {
        this.props.restore((token && { auth_token: token }) || undefined).then(_ => {
          this.props.getProfile();
        }).catch(error => {
          console.log(error);
        });
      });
    }

    if (this.props.isSocketConnected) {
      this.handleConnectedState();
    }
  }

  constructor(props) {
    super(props);

    const {
      lang,
      partnerId,
      location: {
        search,
      },
    } = this.props;

    const configFromUrl = {
      partnerId,
    };

    const langFromUrl = queryStringParser(search, 'lang');
    configFromUrl.lang = langFromUrl || lang;

    if (isMobile()) {
      const exitURL = queryStringParser(search, 'exitURL');
      const partnerId = queryStringParser(search, 'partnerId');

      if (exitURL || partnerId) {
        configFromUrl.exitURL = exitURL;
        configFromUrl.partnerId = partnerId;

        this.props.setConfigs(configFromUrl);
      }
    }

    this.promise = new Promise((resolve, reject) => {
      loadConfigs()
        .then(_ => {
          swarmAPI.init(
            props.language,
            _ => this.onSocketConnect(resolve),
            _ => this.onSocketDisconnect(reject),
            error => this.onSocketError(error),
          );
          !this.state.isChangedLanguage && this.props.setLanguage(configs.langAbbr);
          this.setState({ isPreloading: false });
        })
        .catch(Logger.error);
    });

    postMessageListener.listen('initialConfig', this.configListener);
    postMessageListener.listen('restore_login', this.loginListener);
    postMessageListener.listen('logout', this.logoutListener);
    postMessageListener.listen('setRouteState', this.locationListener);

    /**
     * Moment initial configuration
     * This will be connected with locale change in the applications.
     * Don't forget to connect moment's locale change with application
     * i18n localizations.
     * */
    this.configureMoment();
  }

  configureMoment() {
    /**
     * Applications language abbreviations are using ISO 639-2 Code.
     * Moment library is using ISO 639-1 Code language abbreviations which is
     * covering less languages than the first one.
     * TODO find a solution for abbreviations maping
     *
     * Link to language standard.
     * https://www.loc.gov/standards/iso639-2/php/code_list.php
     * */

    /**
     * Set thresholds to be at their "max" value
     * */
    moment.relativeTimeThreshold('s', 59);
    moment.relativeTimeThreshold('m', 59);
    moment.relativeTimeThreshold('h', 23);
    moment.relativeTimeThreshold('d', 28);
    moment.relativeTimeThreshold('M', 11);

    /**
     * TODO 'en' should be replaced later.
     * */
    moment.updateLocale('en', {
      // at this point only needed relative time override
      // Use this code segment for other confiurations too.
      relativeTime: {
        future: 'in %s',
        past: '%s ago',
        s: '%d seconds',
        ss: '%d seconds',
        m: 'a minute',
        mm: '%d minutes',
        h: 'an hour',
        hh: '%d hours',
        d: 'a day',
        dd: '%d days',
        M: 'a month',
        MM: '%d months',
        y: 'a year',
        yy: '%d years',
      },
    });
  }

  onSocketConnect = resolve => resolve(this.props.changeSocketConnectionStatus(true));

  onSocketDisconnect = reject => reject(this.props.changeSocketConnectionStatus(false));

  configListener = e => {
    const { lang } = e.data.data;
    this.props.setLanguage(lang);
    this.setState({ isChangedLanguage: true });
  };

  loginListener = e => {
    /* eslint-disable */
    const {
      data: {
        user_id,
        auth_token,
      } = {},
    } = e.data;

    this.promise.then(_ => {
      if (user_id && auth_token) {
        /* eslint-enable */
        this.props.restore({
          user_id,
          auth_token,
        }).then(_ => {
          this.props.getProfile();
        });
      }
    });
    /* eslint-enable */
  }

  logoutListener = _ => {
    this.promise.then(_ => this.props.logout());
  }

  locationListener = e => {
    const data = e.data.data;

    if (Object.keys(data).length > 1) {
      navigate(`/${data.route}/${data.compId}/${data.gameId}/${data.date}`);
    } else {
      navigate(`/${data.route}`);
    }
  }

  onSocketError = error => Logger.error(error);

  handleConnectedState = _ => {
    this.props.getPartnerConfig();
  };

  componentDidUpdate(prevProps) {
    if (!prevProps.isSocketConnected && this.props.isSocketConnected) {
      this.handleConnectedState();
    }
  }

  componentWillUnmount() {
    postMessageListener.remove(this.loginListener);
    postMessageListener.remove(this.logoutListener);
    postMessageListener.remove(this.locationListener);
    postMessageListener.remove(this.configListener);
  }

  render() {
    return !this.state.isPreloading && this.props.isSocketConnected
      ? (
        <TranslationProvider language={this.props.language} setLanguage={this.props.setLanguage}>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <AppContent />
          </MuiPickersUtilsProvider>
        </TranslationProvider>
      )
      : <Loader/>;
  }
}

const mapBaseActions = [
  baseActions.setConfigs,
  baseActions.getPartnerConfig,
  baseActions.changeSocketConnectionStatus,
];

const mapPreferencesContextToProps = state => ({
  language: getLanguage(state),
});

const mapBaseContextToProps = state => ({
  isSocketConnected: getSocketConnectionStatus(state),
  lang: getLang(state),
  partnerId: getPartnerId(state),
});

const mapAuthContextToProps = state => ({
  authConfig: getConfig(state),
  profile: getProfile(state),
});

const mapAuthActions = [
  authActions.login,
  authActions.restore,
  authActions.getProfile,
  authActions.logout,
];

const mapPreferencesActions = [
  preferencesActions.setLanguage,
];

export default baseConsumerDecorator(
  preferencesConsumerDecorator(
    authConsumerDecorator(
      withRouter(App),
      mapAuthContextToProps,
      mapAuthActions,
    ),
    mapPreferencesContextToProps,
    mapPreferencesActions,
  ),
  mapBaseContextToProps,
  mapBaseActions,
);
