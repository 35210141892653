import { configureStore } from 'context-api-store';
import PmuStoreActions from 'applications/PMURaces/store/actions';
import defaultState, { storeName } from 'applications/PMURaces/store/defaultState';

const caching = function(state) {

  const storage = {};

  // write cachable content here
  storage.betSleep = state.betSleep;
  storage.betHistoryFilterData = state.betHistoryFilterData;

  return storage;
};

const {
  StoreProvider: PmuStoreProvider,
  consumerDecorator: pmuConsumerDecorator,
} = configureStore(storeName, defaultState, PmuStoreActions, caching);

export {
  pmuConsumerDecorator,
};

export default PmuStoreProvider;
