import ErrorHandler from 'middlewares/errorhandler';

class SocketErrorHandler extends ErrorHandler {

  // Socket specific error handling layer
  manage(error) {
    super.manage(`Socket: ${error}`);
  }
}

export default new SocketErrorHandler();
