const gameCategoryEnums = {
  'PLAT': { name: 'Plat', icon: 'bc-i-discipline-plat' },
  'TROTATTELE': { name: 'Atelle', icon: 'bc-i-discipline-trot-attele' },
  'TROTMONTE': { name: 'Monte', icon: 'bc-i-discipline-trot-monte' },
  'HAIE': { name: 'Haies', icon: 'bc-i-discipline-obstacle' },
  'CROSS': { name: 'Obstacle Cross', icon: 'bc-i-discipline-obstacle' },
  'STEEPLE': { name: 'Steeple', icon: 'bc-i-discipline-obstacle' },
};

export {
  gameCategoryEnums,
};
