/** The benefit of this component is guarding routes,
 * that have some reason not be shown to the client, when it
 * is requested.
 * @Component PrivateRoute
 * @prop {HTMLCollection} component
 */

import React from 'react';
import { Route, Redirect } from 'react-router-dom';

import Auth from 'middlewares/auth';

class PrivateRoute extends React.PureComponent {

  render() {
    const { component: Component, ...rest } = this.props;

    return (
      <Route
        {...rest}
        render={props => Auth.isLoggedIn
          ? <Component {...props} />
          : <Redirect
            to={{
              pathname: '/',
              state: {
                from: props.location,
              },
            }}
          />
        }
      />
    );
  }
};

export default PrivateRoute;
