import swarmAPI from 'connection/socket';

class SubscribedCalls {

  subscribedActions = [];

  constructor(actions, rid) {
    actions && rid && this.addAction(actions, rid);
  }

  addAction(actions, rid) {
    if (this.subscribedActions[actions]) {
      this.unsubscribe(rid);
    }
    this.subscribedActions[actions] = rid;
  }

  unsubscribe(rid) {
    swarmAPI.unsubscribe(rid);
  }

}

export const subscribedCalls = new SubscribedCalls();
