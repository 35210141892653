import { configureStore } from 'context-api-store';
import TranslationsStoreActions from './actions';
import defaultState, { storeName } from './defaultState';

// can be changed to read from config
// or with helper function
const caching = function(state) {

  const storage = {};

  // write cachable content here

  return storage;
};

/* Configuring applications base storage.
 * This is storage over React ContextAPI.
 * Configuration function returns a provider
 * for providing storage data(Note, it must be parent of the storage `users`,
 * either the data will not be accessed) and a decorator function
 * that can wrap React Component and subscribe it to requested changes.
 */

const {
  StoreProvider: TranslationsStoreProvider,
  consumerDecorator: translationsConsumerDecorator,
} = configureStore(storeName, defaultState, TranslationsStoreActions, caching);

/**
 * TranslationProvider class will get selected language from props
 * after which it will add selected language translations in
 * TranslationProvider. It can be used from any part of project to
 * get right texts in selected language
 */
class TranslationProvider extends TranslationsStoreProvider {
  componentDidMount() {
    this.actions.fetchLocal(this.props.language, this.props.setLanguage);
  }

  componentDidUpdate(prevProps) {
    if (this.props.language !== prevProps.language && !this.state[this.props.language]) {
      this.actions.fetchLocal(this.props.language, this.props.setLanguage);
    }
  }
}

export {
  translationsConsumerDecorator,
};
export default TranslationProvider;
