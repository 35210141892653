import React, { useState, useEffect } from 'react';

import configs from 'configs';
import api from 'connection/xhr';
import Logger from 'logger';
import { WindowMessages } from 'connection/postMessages';

import Loader from 'components/stateless/loader';

const PMU = () => {

  /**
   * @function bootstrap
   * @description
   * Main function to add skin specific configurations to application's
   * main configurations. At first glance there is no need to merge
   * configurations here before applications will mount, but
   * the problem is that the configuration needs to be in the static folder,
   * for being more flexible, replaceable. Main reason for this function is
   * webpack indexing process. As webpack indexing is harmful process
   * (for example `hot-module-replacement` module). It must be done before
   * application needs to be loaded in browser. Webpack needs to
   * cut application bundles into chunks, then index them for making chunks
   * communication more relevant. So in case of static import process, webpack
   * finds the paths where imports are included and tries to run them.
   * According to the application business logic there are cases for some
   * dynamic imports, that are based on skin specific configurations.
   * Running them before skin configuration will be merged must be a
   * reason for runtime error (webpack is running files remember).
   * So using dynamic imports for main application is an approach for not running over
   * exception. NOTE :: Maybe not most right or elegant solution for this case.
   */

  const [appIsReady, setAppIsReady] = useState(false);

  useEffect(() => {
    api.custom({
      url: '/configs.json',
      method: 'Get',
    })
      .then(skinConfigsRequest => {
      // presentation layer here
        configs.skin = skinConfigsRequest;

        // Window post message to notify that application is ready
        // to listen window message events from parent frame
        WindowMessages.call({ action: 'appReady' });
        PMU.Wrapper = require('./wrapper').default;
        setAppIsReady(true);
      })
      .catch(Logger.error);
  }, []);

  return appIsReady ? <PMU.Wrapper /> : <Loader />;
};

export default PMU;
