import { configureStore } from 'context-api-store';
import AuthStoreActions from './actions';
import defaultState, { storeName } from './defaultState';

import { isMobile } from 'helpers';

// can be changed to read from config
// or with helper function
const caching = function(state) {

  const storage = {};

  // write cachable content here
  if (isMobile()) {
    storage.authConfig = state.authConfig;
  }

  return storage;
};

/* Configuring applications base storage.
 * This is storage over React ContextAPI.
 * Configuration function returns a provider
 * for providing storage data(Note, it must be parent of the storage `users`,
 * either the data will not be accessed) and a decorator function
 * that can wrap React Component and subscribe it to requested changes.
 */

const {
  StoreProvider: AuthStoreProvider,
  consumerDecorator: authConsumerDecorator,
} = configureStore(storeName, defaultState, AuthStoreActions, caching);

export {
  authConsumerDecorator,
};

export default AuthStoreProvider;
