/**
 * Merge two objects arrays
 * @param array1 - Array in which we need merge second array
 * @param array2 - Array which we need to merge with first array
 * @param array1key - key name of first array property which we need to compare
 * @param array2key - key name of second array property which we need to compare
 * @param propName - name with which matched values will merge in first array
 */
export const mergeArray = (array1 = [], array2 = [], array1key, array2key, propName) => array1.map(item => ({
  ...item,
  [propName]: array2.find(item2 => item2[array2key] === item[array1key]),
}));
