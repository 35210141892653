import api from 'connection/xhr';
import Fingerprint2 from 'fingerprintjs2';

import configs from 'configs';
import Logger from 'logger';

import queryStringParser from './queryStringParser';

const checkLanguageAvailability = lang => configs.skin.availableLanguages[lang] ? lang : '';

/** @function getLanguageFromQueryString
 * @description
 * Function needs to get query string from the current browser url.
 * According the business logic there can be need for forcing application
 * to change language by redirection. The main approache for doing
 * it is passing some parameters as query string.
 */

const getLanguageFromQueryString = _ => {

  const queryString = window.location.href.split('?')[1];
  if (queryString) {
    return checkLanguageAvailability(queryStringParser(queryString, 'lang'));
  }
  return '';
};

const getLanguageFromLocalStorage = _ => {
  const preferences = JSON.parse(window.localStorage.getItem('preferences'));
  if (preferences && preferences.lang) {
    return checkLanguageAvailability(preferences.lang);
  }
  return '';
};

/** @function getLanguageFromGeoSwitch
 * @param {Object} geoIPLangSwitch
 * @param {boolean} geoIPLangSwitch.isEnabled
 * @param {Object} geoIPLangSwitch.locations
 * @param {string} code
 */

const getLanguageFromGeoSwitch = (skin, geoData) => {
  if (skin.geoIPLangSwitch.isEnabled) {
    if (!geoData || !geoData.data) {
      return '';
    }
    return checkLanguageAvailability(skin.geoIPLangSwitch.locations[geoData.data.countryCode]);
  }
  return '';
};

const loadConfigs = async(partnerId, lang) => {

  /* Application business logic required multiple cases for language choice.
    They are separated by priorities.
    1) Can be passed as a query param. Has the highest priority
    2) Can be loaded from localstorage as user preference
    4) Can be loaded from skin configuration as prefered language for some locations.
      It can be enabled/disabled.
    5) Otherwise choice is default language
  */

  // getting geo information
  let geoData = { data: null };

  new Fingerprint2().get(result => configs.fingerPrint = result);

  try {
    geoData = await api.custom(`${configs.geoUrl}?type=json`).catch(Logger.error);
  } catch (err) {
    Logger.error(`geoData loading failed: ${err}`);
  }

  if (configs.skin) {
    const langAbbr = getLanguageFromQueryString() ||
      getLanguageFromLocalStorage() ||
      getLanguageFromGeoSwitch(configs.skin, geoData.data) ||
      configs.skin.preferences.language;

    configs.langAbbr = lang || langAbbr;
    if (partnerId) {
      configs.skin.id = Number(partnerId);
    }

  } else {
    Logger.error(`Initial configs can't be loaded. Skin configs loading failed ::`);
  }
};

export default loadConfigs;
