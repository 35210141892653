import { ErrorHandler } from 'middlewares';

class XHRErrorHandler extends ErrorHandler {

  // XHR specific error handling layer
  manage(error) {
    super.manage(error);
  }
}

export default new XHRErrorHandler();
