export const storeName = 'base';

export default {
  // object represents stores default state for mainPart applications
  isSocketConnected: false,
  helmetInfo: {},
  toasts: [],
  rate: {},
  exitURL: '',
  partnerId: 1,
  lang: 'eng',
};
