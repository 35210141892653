import Observer from './base';
import commandList from './commandList';
import Logger from 'logger';

class WindowMessages extends Observer {

  call({ action, page, tab, ...rest }, callback) {
    if (commandList.includes(action)) {
      super.call({
        tab,
        page,
        action,
        ...rest,
      }, callback);
    } else {
      Logger.warn(`WindowMessages does not support action ${action}`);
    }
  }
}

const windowMessages = new WindowMessages(window);

export default windowMessages;
