export const getState = (store = {}) => store;
export const getMenu = store => getState(store).submenu;
export const getBetSleepInfo = store => getState(store).betSleep;
export const getCompetitions = store => getState(store).competitions;
export const getSelectedGame = store => getState(store).selectedGame;
export const getSelectedHorses = store => getBetSleepInfo(store).horses;
export const getSelectedMarket = store => getState(store).selectedMarket;
export const getSelectedCompetition = store => getState(store).selectedCompetition;
export const getGames = store => getState(store).games;
export const getFinishedGames = store => getState(store).finishedGames;
export const getGamesByCompetitionId = (store, competitionId) => getFinishedGames(store)[competitionId] || {};
export const getBetHistory = store => getState(store).betHistory;
export const getBetHistoryFilterData = store => getState(store).betHistoryFilterData;
export const getSelections = store => getState(store).gamesFinalRates;
export const getStartDate = store => getState(store).startDate;
export const getEndDate = store => getState(store).endDate;

/**
 * Betslip
 */

export const getRaceInfoByGameIdAndCompetitionId = (store, competitionId, gameId) => {
  const game = getGamesByCompetitionId(store, gameId);
  return !game ? {} : game;
};
export const getSelectedBetType = store => getBetSleepInfo(store).betType;
export const getRaceInfoDetailed = (store, competitionId, gameId) => getRaceInfoByGameIdAndCompetitionId(store, competitionId, gameId).detailed || {};
export const getRaceInfoDividends = (store, competitionId, gameId) => getRaceInfoDetailed(store, competitionId, gameId).dividends || [];
export const getRaceInfo = (store, competitionId, gameId) => getRaceInfoDetailed(store, competitionId, gameId).raceInfo || {};
export const getRaceInfoFormulations = (store, competitionId, gameId) => getRaceInfo(store, competitionId, gameId).formulations || [];
export const getRaceInfoParticipants = (store, competitionId, gameId) => getRaceInfo(store, competitionId, gameId).participants;
export const getParticipantsCount = (store, competitionId, gameId) => getRaceInfoParticipants(store, competitionId, gameId).length;
export const getMarkets = store => getState(store).markets;
export const getMarketByGameId = (store, gameId) => getMarkets(store)[gameId];
export const getEvents = store => getState(store).events;
export const getEventsByMarketId = (store, marketId) => getEvents(store)[marketId];

/**
 *  UI
 */

export const getDateFilterForFinished = store => getState(store).dateFilterForFinished;

/**
 * CMS
 */

export const getCmsTexts = store => getState(store).cmsTexts;
export const getCmsBanners = store => getState(store).cmsBanners;
