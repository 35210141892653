import moment from 'moment';

const convertToNeededFormat = (time, neededFormat) => moment(time * 1000).format(neededFormat);

const diffTimezoneUnix = moment().utc().startOf('day').unix() - moment().startOf('day').unix();

const dateFormat = 'DD-MM-YYYY HH:mm';

/**
 * Converted date to londone timezone
 * @param {moment} date
 */
const changeTimezone = date => {
  const dateTs = (date || moment()).unix();

  if (moment().startOf('day') <= date && date < moment().endOf('day')) {
    return moment((dateTs + diffTimezoneUnix) * 1000).utc();
  }

  /**
   * Set 12 hour to not change the date during timezone convert
   */

  return moment(date.format(dateFormat), dateFormat).hour(12).utc();
};

/**
 * Converted unix timestamp to londone timezone unix
 * @param {number} ts
 */
const toLondonTimeByTs = ts => ts - diffTimezoneUnix;

const showTimeInDisplay = ts => (ts ? moment(ts) : moment()).format('HH[:]mm');

export {
  convertToNeededFormat,
  toLondonTimeByTs,
  showTimeInDisplay,
  changeTimezone,
};
