import { Actions } from 'context-api-store';

import configs from 'configs';
import swarmAPI from 'connection/socket';
import produce from 'immer';

class BaseStoreActons extends Actions {

  changeSocketConnectionStatus = isSocketConnected => this.store({ isSocketConnected });

  getRate = (currency = configs.defaultCurrency) => {
    swarmAPI.manage({
      command: 'get',
      params: {
        source: 'config.currency',
        what: {
          currency: [],
        },
        where: {
          currency: {
            name: currency,
          },
        },
      },
    }, result =>
      this.store(produce(draftState => {
        draftState.rate = result.data.data.currency;
      })),
    error => this.store(
      produce(draftState => {
        draftState.toasts.push({ ...error, status: 'error' });
      })));
  };

  getPartnerConfig = _ => {
    swarmAPI.manage({
      command: 'get',
      params: {
        source: 'partner.config',
        what: {
          partner: [],
        },
        subscribe: true,
      },
    }, result => {
      // needs to ne changed to get the partner Id from existing config that established connection with Swarm
      const partner = result.data.data.partner;
      const key = Object.keys(partner)[0];
      const partnerConfig = partner[key];
      configs.partnerConfig = partnerConfig;
      this.store({ partnerConfig });
    });
  }

  changeHelmeInfio = helmetInfo => {
    this.store({ helmetInfo });
  }

  setConfigs = ({ exitURL, partnerId, lang }) => {
    this.store({ exitURL, partnerId, lang });
  }

  /**************
   * UI ACTIONS *
   **************/

  removeToastById = rid => this.store(produce(draftState => {
    draftState.toasts = draftState.toasts.filter(item => item.rid !== rid);
  }));
}

export default BaseStoreActons;
