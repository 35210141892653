class Observer {

  constructor(context) {
    this.context = context;
  }

  call(message, callback) {
    this.context.parent.postMessage(message, '*');
  }
};

export default Observer;
