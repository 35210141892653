/** Class SocketConnection
 * Represent simple socket connection instance
 * on Browsers WebSocket API
 */

import errorHandler from './errorHandler';
import Logger from 'logger';

class SocketConnection {

  /**
   * Create a socket connection
   * @param {string} hostUrl - hosted websocket url to connect
   * @param {Object} options - passing arguments for custom protocol(sub protocol) usage
   */

  constructor(hostUrl, options) {
    this.hostUrl = hostUrl;
    this.options = options;
  }

  /**
   * Connecting to given host
   * @param {function} onConnect
   * @param {function} onError
   * @param {function} onDisconnect
   * @param {function} handler
   */

  start(onConnect, onError, onDisconnect, handler) {
    // eslint-disable-next-line
    this.connection = new WebSocket(this.hostUrl);
    Logger.info(`Socket: trying to connect ${this.hostUrl}`);

    this.setDefaultHandlers(onConnect, onError, onDisconnect, handler);
  }

  /**
   * Set event handlers on websocket connection
   * @param {function} onConnect - the function will be called after socket connection is established
   * @param {function} onError - the function will be called on socket error
   * @param {function} onDisconnect - the function will be called after socket connection will be losed
   * @param {function} handler
   */

  setDefaultHandlers(onConnect, onError, onDisconnect, handler) {
    this.connection.onopen = _ => {
      onConnect && onConnect();
      Logger.success(`Socket connection with ${this.hostUrl} established`);
    };

    this.connection.onclose = _ => {
      onDisconnect && onDisconnect();
      // disconnect strategy
    };

    this.connection.onerror = error => {
      onError && onError(error);
      errorHandler.manage(error);
    };

    this.listen(handler);
  }

  /**
   * @param {function} handler - the function will be called when any frames will received
   * @return {object} - As the response is in JSON format automat parse the response
   */

  listen(handler) {
    this.connection.onmessage = result => handler(JSON.parse(result.data));
  }

  /**
   * @param {object} message - any data needs to published in socket connection
   */

  publish(message) {
    if (this.connection.readyState === 1) {
      this.connection.send(JSON.stringify(message));
    } else {
      Logger.info(this.connection.readyState === 2 ? 'connection is busy' : 'connection closed');
    }
  }

  /**
   * Force close socket connection instance
   */

  close() {
    this.connection.close();
    Logger.info('Socket connection closed');
  }
}

export default SocketConnection;
