import React from 'react';
import PropTypes from 'prop-types';

const Toast = props => {
  const className = props.status === 'error' ? 'pmu-m-b-i error' : 'pmu-m-b-i success';

  return (
    <div onClick={props.onClick} className={className}>
      <p className="pmu-m-b-i-t">{props.msg}</p>
      {props.autoClose && <button className="pmu-m-b-i-btn" onClick={props.onDismissClick}>
        <i className="pmu-m-b-i-btn-icon bc-i-close-remove"></i>
      </button>}
    </div>
  );
};

Toast.propTypes = {
  status: PropTypes.string.isRequired,
  autoClose: PropTypes.bool,
  onDismissClick: PropTypes.func,
  msg: PropTypes.string.isRequired,
};

export default React.memo(Toast);
