import moment from 'moment';

export class CustomInterval {

  callbacks = [];

  constructor(minutesInDay = 1440, millisecondsInMinute = 60000, millisecondsInSecond = 1000, fullPercentage = 100) {
    this.minutesInDay = minutesInDay;
    this.millisecondsInMinute = millisecondsInMinute;
    this.millisecondsInSecond = millisecondsInSecond;
    this.fullPercentage = fullPercentage;
  }

  addCallbacks(callback) {
    this.stop();
    this.callbacks.push(callback);
    this.start();
  }

  get allCallbacks() {
    return this.callbacks;
  }

  start() {
    this.stop();
    this.timerId = setTimeout(_ => {
      this.allCallbacks && this.allCallbacks.forEach(callback => callback());
      this.intervalId = setInterval(_ => {
        this.allCallbacks && this.allCallbacks.forEach(callback => callback());
      }, this.millisecondsInMinute);
    }, this.millisecondsInMinute - moment().format('ss') * this.millisecondsInSecond);
  }

  stop() {
    this.timerId && clearTimeout(this.timerId);
    this.intervalId && clearInterval(this.intervalId);
  }
}
