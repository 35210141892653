import SocketConnection from './base';
import Logger from 'logger';
import Network from '../network';

import configs from 'configs';

import { loadCaptcha } from 'helpers';
import queryStringParser from 'helpers/queryStringParser';

/** Class SwarmAPI
 * Web platform will use a backend based on WEBSockets.
 * So here is represented API integration for that socket backend
 * named SWARM. SWARM is a generic backend designed to give flexibility
 * to client in getting data. SWARM allows receiving data with custom filters.
 */

class SwarmAPI {

  /**
   * @prop {string[]} COMMANDS - Swarm api valid commands
   */

  static COMMANDS = [
    'get',
    'ping',
    'login',
    'logout',
    'do_bet',
    'whats_up',
    'unsubscribe',
    'restore_login',
    'cancel_pmu_bet',
    'get_pmu_matches',
    'request_session',
    'payment_services',
    'get_result_games',
    'get_bonus_details',
    'validate_recaptcha',
    'get_pmu_bet_history',
    'get_pmu_selections_by_match_id',
  ];

  static CODES = {
    // OK: 0,
    SESSION_LOST: 5,
    // NEED_TO_LOGIN: 12
  };

  initializeHandlers = {};

  /**
   * @prop {number} requestCount - A counter for requests
   */

  requestCount = 0;

  /**
   * @prop {Object} stack - The object represent stack of requests options, that has been
   * requested during active in memory session. The main reason for this caching is that
   * when the resource is requested via client interaction and it must be subscribed to the
   * requested resource update, there can be case when another concurent resource is requested.
   * As only one request can be presented to client and as only after response application will get
   * the subscription id, so there is a need to wait until te response comes. Keeping requested resource
   * in stack can provide more flexible functionality for this case.
   * @prop {string} stack.subid - If the published command contains subscription
   * the client will be subscribed to the info and receive updates. Id can be used for
   * unsubscribe from updates.
   * @prop {function} stack.onError - call this function with error after backend's handled error result
   * @prop {function} stack.handler - call this function with result after successful result
   * @prop {boolean} stack.broadcastable - checking this property each time before calling passed handler functions
   */

  stack = {};

  /**
   * Initializing swarm connection based on applications configurations
   * @return {Promise}
   */

  constructor(count = 5, timeout = 200, rounds = 300) {
    this.reconnect = {
      count,
      rounds,
      timeout,
    };
  }

  /**
   * Initialization process must contain storing default configurations to
   * memory, and exactly configuring. In cases of lost connection, or dron unavailability
   * configuration process must be restored.
   * @param {Object} params - configuration object
   * @param {function} onConnect - Calling this function each time connection with Swarm
   * api is established in current session end or user command
   * @param {function} onDisconnect - WebSocket connection iteraption
   * @param {function} onError - WebSocket APi propagated error events
   */

  init(params, onConnect, onDisconnect, onError) {
    this.initializeHandlers = { params, onConnect, onDisconnect, onError };
    this.configureAndStart();
  }

  /**
   * After connection is established, Swarm requires to
   * create a session with him. This function will be called after the requested
   * session is created.
   */

  onConnect(connectionStatus) {
    /* minimal needed atomar functions
     for creating connection with swarm */
    this.forceClose = false;
    this.timerId && this.clearInterval(true);
    this.requestSession(connectionStatus);
  }

  /**
   * @param {Event} message - Propagated event from WebSockets API on connection close
   * In case of connection error it'll try to reconnect by given strategy.
   */

  onError(message) {
    if (message && message.type === 'error' && message.target && message.target.url === configs.skin.socket.url) {
      !this.timerId && this.runReconnectStrategy();
    }
    // this.initializeHandlers.onError();
  }

  /**
   * Backend handled errors can be in two fases,
   * when the error is provided as response and when its
   * just an interaption event
   * @param {Object} result
   * @return {boolean}
   */

  static hasHandledBackendErrors(result) {
    if (!Reflect.has(result, 'rid')) {
      // dron not available
      return false;
    }
    // we may need to develop a strategy for handling the error code NEED_TO_LOGIN
    return !result.msg || result.code !== SwarmAPI.CODES.SESSION_LOST;
  }

  /**
   * @param {?string} message - There's need to be connection close
   * according to client applications' business logic. This will also interapt a disconnection
   * event. Reconnection strategy will be run only when the interaption was
   * not client's application request.
   */

  onDisconnect(message) {
    if (!this.forceClose) {
      !this.timerId ? this.runReconnectStrategy() : Logger.info('reconnection strategy is already running');
    }
    this.initializeHandlers.onDisconnect();
  }

  /**
   * According network connection status strategy will be run
   * on nearest `online` connection
   */

  runReconnectStrategy() {
    Logger.info('reconnect strategy run');
    if (Network.checkNetworkState()) {
      this.runStrategyLogic();
    } else {
      Network.callAfterConnect(() => this.runStrategyLogic());
      Logger.warn('wait until online');
    }
  }

  /**
   * Emitting information about disconnection to client application
   * According the configuration will be restored lost session with
   * Swarm backend
   */

  runStrategyLogic() {
    this.initializeHandlers.onError();

    const count = 0;
    this.runTimeout(count);
  }

  runTimeout(count) {
    if (count < this.reconnect.count) {
      this.timerId = setTimeout(_ => {
        this.configureAndStart('reconnection');
        this.runTimeout(++count);
      }, this.reconnect.timeout + this.reconnect.round * count);
    } else {
      this.clearInterval();
      // run another strategy
    }
  }

  /**
   * @param {Object} result - Swarm response
   * @param {number} result.code - status code
   * @param {Object} result.data - actual response / response body
   * @param {string} rid - request id for which response is provided
   * The requestId is provided for somelike Http verb support
   */

  handler(result) {
    if (SwarmAPI.hasHandledBackendErrors(result)) {
      const manages = this.findHandlersInStack(result);
      manages.forEach(manage => {
        manage
          ? result.msg
            ? manage.onError(result)
            : result.rid === '0'
              ? manage.update(result.data[manage.subid])
              : manage.handler(result)
          : Logger.warn(`There in no request made with id: ${result.rid}`);
      });
    } else {
      this.manageDronUnAvailability(result.error || result.msg);
    }
  }

  /**
   * @param {Object} message - any Object needed to be passed to swarm
   * @param {function} handler
   * @param {function} onError [Logger.error]
   * @param {function} update
   * @return {number} requestId - generated request unique id for each made request
   */

  manage(message, handler, onError = Logger.error, update = _ => void 0) {
    const request = this.request(message);

    return new Promise((resolve, reject) => {
      this.stack[request] = {
        onError: err => reject(onError(err)),
        update,
        message,
        subid: null,
        broadcastable: true,
        handler: this.safeHandler(handler, resolve),
        needsSubscription: message.params ? !!message.params.subscribe : false,
      };
    });
  }

  /**
   * @param {string} rid - request id that needs to be unsubscribed after response will come,
   * which means after the subscription id will be proved
   * @param {handler} handler - function that needs to be called after subscription is canceled
   */

  unsubscribe(rid, handler = _ => void 0) {
    const manage = this.stack[rid];
    if (manage) {
      manage.broadcastable = false;
      if (manage.subid) {
        const requestId = this.makeUnsubscribeRequest(manage.subid);
        this.stack[requestId] = {
          handler,
          subid: null,
        };
      }
    }
  }

  /**
   * @param {string} subid - subscription id provided from backend.
   * The subscription under this id will be canceled
   * @return {string} rId
   */

  makeUnsubscribeRequest(subid) {
    const rid = this.generateRequestId();
    const message = {
      command: 'unsubscribe',
      params: { subid },
      rid,
    };
    this.connection.publish(message);
    return rid;
  }

  /**
   * @param {string} error
   */

  manageDronUnAvailability(error) {
    Logger.warn(error);
    !this.timerId && this.runReconnectStrategy();
  }

  initConnection(res, connectionStatus) {
    this.initializeHandlers.onConnect(res);
    if (connectionStatus) {
      this.restoreActiveSubscriptions();
    }
  }

  destructRecaptcha(result) {
    const {
      data: {
        recaptcha_enabled: recaptchaEnabled,
        recaptcha_version: recaptchaVersion,
        site_key: siteKey,
      } = {},
    } = result;

    return {
      siteKey,
      recaptchaVersion,
      recaptchaEnabled,
    };
  }

  /**
   * Session request from Swarm backend. Connection will be
   * established for the client application after the session will be
   * received.
   */

  requestSession(connectionStatus) {
    // needs to be changed, info must come from configs

    const partnerId = queryStringParser(window.location.search, 'partnerId');
    const lang = queryStringParser(window.location.search, 'lang');
    const siteKey = window.localStorage.getItem(`reCAPTCHA_site_key_${configs.skin.id}`);
    const params = {
      afec: configs.fingerPrint,
      language: lang || configs.langAbbr,
      site_id: partnerId || configs.skin.id,
    };

    if (siteKey) {
      loadCaptcha(siteKey, 'session_opened', token => {
        params['g_recaptcha_response'] = token;
        return this.manage({
          command: 'request_session',
          params,
        }, res => {
          const {
            recaptchaEnabled,
            recaptchaVersion,
          } = this.destructRecaptcha(res);

          if (!(recaptchaEnabled && recaptchaVersion === 3)) {
            window.localStorage.removeItem(`reCAPTCHA_site_key_${configs.skin.id}`);
          }

          this.initConnection(res, connectionStatus);
        });
      });
    } else {
      return this.manage({
        command: 'request_session',
        params,
      }, res => {
        const {
          recaptchaEnabled,
          recaptchaVersion,
          siteKey,
        } = this.destructRecaptcha(res);

        if (recaptchaEnabled && recaptchaVersion === 3 && siteKey) {
          window.localStorage.setItem(`reCAPTCHA_site_key_${configs.skin.id}`, siteKey);
          loadCaptcha(siteKey, 'session_opened', token => {
            this.manage({
              command: 'validate_recaptcha',
              params: {
                action: 'session_opened',
                g_recaptcha_response: token,
              },
            }, res => {
              if (res.data.result) {
                this.initConnection(res, connectionStatus);
              }
            }, error => console.error(error));
          });
        } else {
          this.initConnection(res, connectionStatus);
        }
      });
    }
  }

  /**
   * @param {function} handler - storing real event handler
   * under a "safe" handler, which will either call passed argument
   * or just automatically unsubscribe the response as the request was
   * marked as non broadcastable
   */

  safeHandler(handler, resolve) {
    return message => {
      const manages = this.findHandlersInStack(message);
      manages.forEach(manage => {
        if (!manage.broadcastable) {
          // not valid code for every time
          // key is expected as subid but can be another key also
          // the expected behaviour can be got if the usage is right maintained
          for (const key in message.data) {
            this.makeUnsubscribeRequest(key);
          }
          return;
        }
        resolve(message);
        handler(message);
      });
    };
  }

  /**
   * Because there is no chance now (09.01.2019) in backend session implementation to
   * restore and activate(resubscribe) all the subscriptions, after socket session reconnection this subscription,
   * will be called manually.
   */

  restoreActiveSubscriptions() {
    for (const requestId in this.stack) {
      if (this.stack[requestId].needsSubscription) {

        // getting new request id if the request has subscription
        const newRequestId = this.request(this.stack[requestId].message);
        /** Now we need ot find out the old request id from the stack and replace it with
         * new request id, but with keeping old credentials.
         */
        // eslint-disable-next-line no-unused-vars
        const { [requestId]: oldRequestId, ...newStack } = { ...this.stack, [newRequestId]: { ...this.stack[requestId] } };
        // replacing the current requests stack with restored subscriptions
        this.stack = newStack;
      }
    }
  }

  /**
   * @param {string} command - single request command from swarm API command list
   * @param {Object} params - params actual available values can be found from SWARM.API document
   */

  constructMessage(command, params) {
    if (!SwarmAPI.COMMANDS.includes(command)) {
      return Logger.error(`SwarmAPI does not support command '${command}'`);
    }

    const rid = this.generateRequestId();

    return {
      rid,
      params,
      command,
    };
  }

  /**
   * Closing socket connection via client application command
   */

  closeConnection() {
    this.forceClose = true;
    this.connection.close();
  }

  /**
   * @param {Object} options
   * @param {Object} options.params - swarm api specific params in object structure (what, where, source)
   * @param {string} options.command - single request command from swarm API command list
   */

  request({ command, params }) {
    if (Network.checkNetworkState()) {
      const message = this.constructMessage(command, params);
      if (message) {
        this.connection.publish(message);
        return message.rid;
      } else {
        Logger.warn(`There is no command: ${command} allowed for swarm API`);
      }
    } else {
      console.log('caching request here');
    }
  }

  /**
   * Requesting new socket session from Swarm backend
   */

  configureAndStart(connectionStatus = null) {
    this.connection = new SocketConnection(configs.skin.socket.url, configs.skin.socket.options);
    // use language and others from `this.initializeHandlers.params`
    this.connection.start(
      // curring is for getting current execution context(required).
      _ => this.onConnect(connectionStatus),
      error => this.onError(error),
      message => this.onDisconnect(message),
      message => this.handler(message),
    );
  }

  /**
   * Finding handler functions for received request id or subscription id
   * @param {Object} result
   * @return {Object} manage{@link SwarmAPI#stack}
   */

  findHandlersInStack(result) {
    // getting handlers manager via request(`rid`) id property
    let manages = result.rid === '0' ? [] : result.rid in this.stack ? [this.stack[result.rid]] : [];
    if (manages.length) {
      // adding subscription
      if (manages[0] && manages[0].needsSubscription && result.data) {
        manages[0].subid = result.data.subid;
      }
    } else {
      // upcoming update
      for (let key in result.data) {
        manages.push(Object.values(this.stack).find(request => request.subid === key));
      }
    }
    return manages;
  }

  /**
   * generating unique id for every request based on current date milliseconds and request counter
   */

  generateRequestId() {
    return `${Date.now()}${this.requestCount++}`;
  }

  /**
   * @param {boolean} isReconnected [false] - Clearing time interval is
   * needed when the connection is established, either when the
   * strategy of reconnection fails.
   */

  clearInterval(isReconnected = false) {
    clearTimeout(this.timerId);
    this.timerId = null;
    isReconnected
      ? Logger.success('Strategy succeeded!!!')
      : Logger.warn(`Reconnection strategy was failed ${this.reconnect.count} time:: trying time interval: ${this.reconnect.timeout}`);
  }
}

export default SwarmAPI;
