
import React from 'react';
import classNames from 'classnames';

class SubMenuItem extends React.PureComponent {

  handleClick = event => {
    this.props.handleClick && this.props.handleClick(event, this.props.id);
  };

  render() {
    return (
      <div className={classNames('pmu-h-i', this.props.classNames)} onClick={this.handleClick}>
        {this.props.title}
      </div>
    );
  }
}

export default SubMenuItem;
